import { IdNameEntity } from './IdNameEntity'

class Company extends IdNameEntity {
    constructor({ id, name, address, link }) {
        super({ id, name })
        this.address = address
        this.link = link
    }
}

export { Company }
