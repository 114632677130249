import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { newsCommunicator } from '../../../communicators/newsCommunicator'

import { SectionTitle } from '../SectionTitle/SectionTitle'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { TopNewsItem } from './TopNewsItem/TopNewsItem'

import './TopNews.css'

class TopNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            markedNews: []
        }
    }

    async loadTopNews() {
        try {
            const { id, queryParam, withApproval } = this.props

            const markedNews = await newsCommunicator.fetchTopNews(queryParam, id, withApproval)
            this.setState({ markedNews })
        } catch (error) {
            console.error(error.message)
        }
    }

    componentDidMount() {
        this.loadTopNews()
    }

    render() {
        const { title } = this.props
        const { markedNews } = this.state

        const newsClass = markedNews.length > 2 ? '' : 'top-news__content__left--centered'

        if (_.isEmpty(markedNews)) {
            return null
        }

        return (
            <section className="top-news container-1280">
                <div className="section-title-centered">
                    <SectionTitle title={title} underlineClass />
                </div>
                <ErrorBoundary>
                    <div className="top-news__content">
                        <div className={`top-news__content__left ${newsClass}`}>
                            {markedNews.slice(0, 2).map((item, i) => (
                                <TopNewsItem newsItem={item} key={item.id} />
                            ))}
                        </div>
                        {markedNews.length > 2 && (
                            <div className="top-news__content__right">
                                <TopNewsItem
                                    newsItem={markedNews[2]}
                                    key={markedNews[2].id}
                                    isVertical
                                />
                            </div>
                        )}
                    </div>
                </ErrorBoundary>
            </section>
        )
    }
}

TopNews.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    queryParam: PropTypes.string.isRequired,
    withApproval: PropTypes.bool
}

export { TopNews }
