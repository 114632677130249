import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { additionalInfoProps } from './propTypes'

import i18n from '../../../config/i18n'

import { SectionTitle } from '../SectionTitle/SectionTitle'
import { AdditionalInfo } from './AdditionalInfo/AdditionalInfo'

import './PageInfo.css'

const PageInfo = ({
    breadcrumbs = i18n.t('breadcrumbs.default'),
    title,
    icon,
    bgImage,
    overlay = false,
    additionalInfo,
    hasGrayBg
}) => {
    bgImage = bgImage || '/images/top-img.jpg'
    const className = overlay ? 'overlay' : ''
    const backgroundImage = icon ? null : { backgroundImage: `url("${bgImage}")` }
    const grayBgClass = hasGrayBg ? 'page-info--gray' : ''

    return (
        <section
            className={`page-info page-info--${className} ${grayBgClass}`}
            style={backgroundImage}
        >
            <div className="container-1280">
                <div className="page-info__breadcrumbs">
                    {breadcrumbs.map((item, index) => (
                        <div className="page-info__breadcrumbs__item" key={index}>
                            <Link to={item.link}>{item.name}</Link>
                        </div>
                    ))}
                </div>
                <div className="page-info__content">
                    <div className="page-info__content__title">
                        <SectionTitle title={title} underlineClass dark />
                        {additionalInfo && <AdditionalInfo additionalInfo={additionalInfo} />}
                    </div>
                    {icon && (
                        <div className="page-info__content__image">
                            <img src={icon} alt={title} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

PageInfo.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string
        })
    ),
    title: PropTypes.string,
    icon: PropTypes.string,
    additionalInfo: additionalInfoProps,
    overlay: PropTypes.bool
}

export { PageInfo }
