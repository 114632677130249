import React, { Component } from 'react'
import ReactGA from 'react-ga'

const defaultOptions = {
    debug: true
}

export function withAnalytics(WrappedComponent, options = defaultOptions) {
    const trackPage = page => {
        ReactGA.set({
            page,
            ...options
        })
        ReactGA.pageview(page)
    }

    const HOC = class extends Component {
        componentDidMount() {
            try {
                const page = this.props.location.pathname
                trackPage(page)
            } catch (err) {
                return null
            }
        }

        componentDidUpdate(prevProps, prevState) {
            const currentPage = this.props.location.pathname
            const prevPage = prevProps.location.pathname

            if (currentPage !== prevPage) {
                trackPage(currentPage)
            }
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    return HOC
}
