import React from 'react'
import PropTypes from 'prop-types'

import './RCSectionBlock.css'

const RCSectionBlock = ({ withContainer = false, dark, children }) => (
    <section className={dark ? 'dark-blue' : ''}>
        {withContainer ? (
            <div className="rc-section-container container-1280">{children}</div>
        ) : (
            children
        )}
    </section>
)

RCSectionBlock.propTypes = {
    children: PropTypes.node,
    dark: PropTypes.bool
}

export { RCSectionBlock }
