import React from 'react'
import PropTypes from 'prop-types'

import './Loader.css'

const Loader = ({ isLoading = true, children }) => {
    if (!isLoading) {
        return children || React.Fragment
    }

    return (
        <div className="loader-container">
            <div className="loader">
                <img className="loader__one" src="/images/loader-part1.png" alt="loader-1" />
                <img className="loader__two" src="/images/loader-part2.png" alt="loader-2" />
                <img className="loader__three" src="/images/loader-part3.png" alt="loader-3" />
                <img className="loader__four" src="/images/loader-part4.png" alt="loader-4" />
            </div>
        </div>
    )
}

Loader.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.node
}

export { Loader }
