import React, { useState, useEffect } from 'react'
import i18n from '../../../../config/i18n'

import './SingleActivityPage.css'

const SingleActivityPage = ({ published = i18n.t('published') }) => {
    const [aktuelnost, setAktuelnost] = useState(null)

    useEffect(() => {
        const lang = JSON.parse(localStorage.getItem('language'))
        const aktuelnostSlug = window.location.pathname.split('/')[2]
        fetch(`https://api.pks.rs/public-api/dubai-expo/aktuelnosti/${aktuelnostSlug}`, {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(response => setAktuelnost(response.body.entry))
    }, [])

    const calcMonth = month => {
        let lang = JSON.parse(localStorage.getItem('language'))
        switch (month) {
            case '01':
                return lang ? 'januar' : 'јануар'
                break
            case '02':
                return lang ? 'februar' : 'фебруар'
                break
            case '03':
                return lang ? 'mart' : 'март'
                break
            case '04':
                return lang ? 'april' : 'април'
                break
            case '05':
                return lang ? 'maj' : 'мај'
                break
            case '06':
                return lang ? 'jun' : 'јун'
                break
            case '07':
                return lang ? 'jul' : 'јул'
                break
            case '08':
                return lang ? 'avgust' : 'август'
                break
            case '09':
                return lang ? 'septembar' : 'септембар'
                break
            case '10':
                return lang ? 'oktobar' : 'октобар'
                break
            case '11':
                return lang ? 'novembar' : 'новембар'
                break
            case '12':
                return lang ? 'decembar' : 'децембар'
                break
            default:
                return lang ? 'mesec' : 'месец'
        }
    }

    const calcHumanReadDate = date => {
        const dateSplited = date.split(' ')[0].split('-')

        return `${dateSplited[2]}. ${calcMonth(dateSplited[1])} ${dateSplited[0]}.`
    }

    return (
        <main>
            {aktuelnost && (
                <>
                    <div className="dubai-expo-2020-top-banner">
                        <div className="dubai-expo-2020-publish-date-card">
                            <p>{published}:</p>
                            <p>{calcHumanReadDate(aktuelnost.publish_date)}</p>
                        </div>
                    </div>
                    <div className="section-container container-1280">
                        <h1 className="top-section-title__text dark top-section-title__text--underlined">
                            {aktuelnost.title}
                        </h1>

                        <div
                            dangerouslySetInnerHTML={{ __html: aktuelnost.content_short }}
                            className="dubai-expo-2020-inner-html-content"
                        />
                        <div className="dubai-expo-aktivnost-headline-image-wrapper">
                            <img
                                className="dubai-expo-aktivnost-headline-image"
                                src={aktuelnost.headline_image.image.original}
                                alt="headline-img"
                            />
                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: aktuelnost.content }}
                            className="dubai-expo-2020-inner-html-content"
                        />
                    </div>
                </>
            )}
        </main>
    )
}

export default SingleActivityPage
