import { RegionalChamber } from '../entities/RegionalChamber'
import { pksApiService } from '../services/pksApiService'

const endPoint = '/regional-chambers'

class RegionalChambersCommunicator {
    async fetchChambersList() {
        const includeFields = ['id', 'name_slug', 'name']
            .map(field => `include[]=${field}`)
            .join('&')

        const chambersEndpoint = `/regional-chambers?${includeFields}`
        const chambers = await pksApiService.fetchData(chambersEndpoint)

        return chambers.map(item => new RegionalChamber(item))
    }

    async fetchChamber(slug) {
        const chamberEndpoint = `/regional-chambers/${slug}`
        const chamber = await pksApiService.fetchData(chamberEndpoint)

        return new RegionalChamber(chamber)
    }

    fetch(id) {}

    fetchPage(pageSize = 20, page = 1, queryParams = {}) {
        return pksApiService
            .fetchPage(endPoint, pageSize, page, queryParams)
            .then(chambersList => chambersList.map(item => new RegionalChamber(item)))
    }
}

export const regionalChambersCommunicator = new RegionalChambersCommunicator()
