import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { navigationProps } from '../propTypes'

import i18n from '../../../../config/i18n'

import './RCHamburgerNavigationList.css'

const RCHamburgerNavigationList = ({
    navigation,
    onLanguageSelectorClick,
    onCloseDropdown,
    langLat,
    title
}) => (
    <div className="RC-hamburger-navigation-list">
        <h4 className="RC-hamburger-navigation-list__chamber-title">{title}</h4>
        <ul className="RC-hamburger-navigation-list__nav">
            {navigation.map((navItem, i) => (
                <li key={i}>
                    <NavLink
                        to={`/strana/${navItem.slug}`}
                        activeClassName="is-active"
                        onClick={onCloseDropdown}
                    >
                        {navItem.title}
                    </NavLink>
                </li>
            ))}
            <li>
                <NavLink to={`/kontakt`} activeClassName="is-active-link" onClick={onCloseDropdown}>
                    {i18n.t('regionalChamber.contact.title')}
                </NavLink>
            </li>
        </ul>
        <ul className="RC-hamburger-navigation-list__options">
            <li onClick={onLanguageSelectorClick} className={langLat ? '' : 'active-language'}>
                ћир
            </li>
            <li onClick={onLanguageSelectorClick} className={langLat ? 'active-language' : ''}>
                lat
            </li>
        </ul>
        <h6 className="RC-hamburger-navigation-list__call-center">
            Call center <span>0800 808 809</span>
        </h6>
    </div>
)

RCHamburgerNavigationList.propTypes = {
    navigation: navigationProps,
    onLanguageSelectorClick: PropTypes.func.isRequired,
    onCloseDropdown: PropTypes.func.isRequired,
    langLat: PropTypes.bool.isRequired,
    title: PropTypes.string
}

export { RCHamburgerNavigationList }
