import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { linkItemProp } from '../propTypes'

import './RCLinkList.css'

const RCLinkList = ({ t, list, domain }) => (
    <ul className="RC-link-list">
        <li key={t('chamberBelgrade.name')}>
            <a
                href={`http://${t('chamberBelgrade.slug')}.${domain}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('chamberBelgrade.name')}
            </a>
        </li>
        {list.map(({ name, slug }, i) => (
            <li key={i}>
                <a href={`http://${slug}.${domain}`} target="_blank" rel="noopener noreferrer">
                    {name}
                </a>
            </li>
        ))}
    </ul>
)

RCLinkList.propTypes = {
    list: PropTypes.arrayOf(linkItemProp)
}

export { RCLinkList }

export default withTranslation()(RCLinkList)
