import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { newsCommunicator } from '../../../../../communicators/newsCommunicator'

import { RCSectionBlock } from '../../../RCSectionBlock/RCSectionBlock'
import { RCAllNewsItem } from './RCAllNewsItem/RCAllNewsItem'
import { ErrorBoundary } from '../../../../components/ErrorBoundary/ErrorBoundary'
import { RCSectionTitle } from '../../../RCSectionTitle/RCSectionTitle'
import { Pagination } from '../../../../components/Pagination/Pagination'

import './RCAllNews.css'

class RCAllNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            unmarkedNews: [],
            pagesList: [],
            lastPage: 1
        }

        this.updateRCAllNews = this.updateRCAllNews.bind(this)
    }

    getPages(n) {
        const array = []
        for (let i = 1; i <= n; i++) {
            array.push(i)
        }

        return array
    }

    async loadRCAllNews() {
        try {
            const { id } = this.props

            const response = await newsCommunicator.fetchRCNews(id, 1, 6)

            this.setState({
                unmarkedNews: response.rcNews,
                lastPage: response.pagination.totalPages,
                pagesList: this.getPages(response.pagination.totalPages)
            })
        } catch (error) {
            console.error(error.message)
        }
    }

    async updateRCAllNews(currentPage) {
        try {
            const { id } = this.props

            const response = await newsCommunicator.fetchRCNews(id, currentPage, 6)

            this.setState({ unmarkedNews: response.rcNews })
        } catch (error) {
            console.error(error.message)
        }
    }

    componentDidMount() {
        const { id } = this.props

        this.loadRCAllNews(id)
    }

    render() {
        const { title } = this.props
        const { unmarkedNews, pagesList, lastPage } = this.state

        if (_.isEmpty(unmarkedNews)) {
            return null
        }

        return (
            <RCSectionBlock withContainer>
                <RCSectionTitle title={title} />
                <ErrorBoundary>
                    <div className="rc-all-news__content container-1280">
                        {unmarkedNews.map((article, i) => (
                            <RCAllNewsItem newsItem={article} key={article.id} />
                        ))}
                    </div>
                </ErrorBoundary>
                {pagesList.length > 1 && (
                    <Pagination
                        lastPage={lastPage}
                        pagesList={pagesList}
                        updateContent={this.updateRCAllNews}
                        light
                    />
                )}{' '}
            </RCSectionBlock>
        )
    }
}

RCAllNews.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
}

export { RCAllNews }
