import _ from 'lodash'

import { DocumentFile } from './DocumentFile'
import { ForeignRepresentative } from './ForeignRepresentative'
import { ImageItem } from './ImageItem'
import { Member } from './Member'

export class Section {
    constructor({
        id,
        title,
        slug,
        description,
        split,
        gallery,
        documents,
        video_materials,
        news_categories,
        application_link,
        dark_theme,
        offices,
        representatives,
        contacts
    }) {
        this.id = id
        this.title = title
        this.slug = slug
        this.textSplit = split
        this.description = description
        this.documents = documents
        this.videoMaterials = video_materials
        this.gallery = gallery || []
        this.applicationLink = application_link
        this.darkTheme = dark_theme
        this.offices = offices ? offices.map(office => new ForeignRepresentative(office)) : null
        this.representatives = representatives
            ? representatives.map(representative => new ForeignRepresentative(representative))
            : null
        this.contactList = contacts
        this.newsCategories = news_categories
    }

    get sectionDescription() {
        const temporalDIV = document.createElement('div')
        temporalDIV.innerHTML = this.description

        return _.truncate(temporalDIV.textContent, { length: 120, separator: ' ' }) || ''
    }

    get hasDescription() {
        return this.description.length > 0
    }

    get htmlDescription() {
        return {
            __html: this.description
        }
    }

    get news() {
        return this.newsCategories
    }

    get split() {
        return Boolean(this.textSplit)
    }

    get galleryImages() {
        return this.gallery.map(image => new ImageItem(image))
    }

    get docs() {
        return this.documents.map(document => {
            return new DocumentFile(document)
        })
    }

    get videos() {
        return this.videoMaterials.map(video => {
            return new DocumentFile(video)
        })
    }

    get hasContacts() {
        return !_.isEmpty(this.contactList)
    }

    get contacts() {
        return this.hasContacts && this.contactList.map(contactPerson => new Member(contactPerson))
    }

    get isDark() {
        return this.darkTheme ? true : false
    }
}
