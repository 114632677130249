import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { regionalChambersCommunicator } from '../../../../communicators/regionalChambersCommunicator'

import { RCPage } from '../../RCPage/RCPage'
import { RCTopNews } from './RCTopNews/RCTopNews'
import { RCAllNews } from './RCAllNews/RCAllNews'

class AllRCNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chamber: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.loadChamber()
    }

    async loadChamber() {
        try {
            const { slug } = this.props
            const chamber = await regionalChambersCommunicator.fetchChamber(slug)

            this.setState({ chamber, isLoading: false })
        } catch (error) {
            console.error(error)
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { t, slug } = this.props
        const { chamber, isLoading } = this.state

        const chamberInfo = t('chamberInfo')[slug]

        return (
            <RCPage
                isLoading={isLoading}
                title={`${chamberInfo.name}/${t('allRCNewsTitle')}`}
                description={`${t('allRCNewsDescription')} - ${chamberInfo.name}`}
            >
                {chamber && (
                    <>
                        <RCTopNews id={chamber.id} title={t('generalNews.topNews.title')} />
                        <RCAllNews id={chamber.id} title={t('generalNews.allNews.title')} />
                    </>
                )}
            </RCPage>
        )
    }
}

AllRCNews.propTypes = {
    t: PropTypes.func
}

export { AllRCNews }
export default withTranslation()(AllRCNews)
