import { pksApiService } from '../services/pksApiService'
import { News } from '../entities/News'
import { Event } from '../entities/Event'
import { PaginationInfo } from '../entities/PaginationInfo'

class SearchCommunicator {
    async searchNews(searchTerm, page) {
        const searchEndpoint = '/search'

        const queryParams = {
            page,
            module: 'news',
            offset: 2,
            term: searchTerm
        }

        const responseBody = await pksApiService.fetchDataWithMeta(searchEndpoint, queryParams)

        const pagination = responseBody.pagination
            ? new PaginationInfo(responseBody.pagination)
            : null
        const news = responseBody.entries ? responseBody.entries.map(news => new News(news)) : []

        return {
            pagination,
            news
        }
    }

    async searchEvents(searchTerm, page) {
        const searchEndpoint = '/search'

        const queryParams = {
            page,
            module: 'events',
            offset: 4,
            term: searchTerm
        }

        const responseBody = await pksApiService.fetchDataWithMeta(searchEndpoint, queryParams)
        const pagination = responseBody.pagination
            ? new PaginationInfo(responseBody.pagination)
            : null
        const events = responseBody.entries
            ? responseBody.entries.map(event => new Event(event))
            : []

        return {
            pagination,
            events
        }
    }
}

export const searchCommunicator = new SearchCommunicator()
