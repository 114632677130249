import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Pagination.css'

class Pagination extends Component {
    constructor(props) {
        super(props)

        this.state = {
            startPage: 1,
            currentPage: 1
        }

        this.pageClick = this.pageClick.bind(this)
        this.decPage = this.decPage.bind(this)
        this.incPage = this.incPage.bind(this)
    }

    pageClick(e) {
        const { updateContent } = this.props
        updateContent(parseInt(e.target.id, 10))
        this.setState({ currentPage: parseInt(e.target.id, 10) })
    }

    decPage() {
        const { updateContent } = this.props
        const { startPage, currentPage } = this.state

        if (currentPage === 1) {
            return
        }

        if (startPage > 1) {
            this.setState(prevState => ({ startPage: prevState.startPage - 1 }))
        }

        updateContent(currentPage - 1)
        this.setState(prevState => ({ ...prevState, currentPage: prevState.currentPage - 1 }))
    }

    incPage() {
        const { lastPage, updateContent } = this.props
        const { currentPage, startPage } = this.state

        if (currentPage === lastPage) {
            return
        }

        if (currentPage === startPage + 2 && currentPage < lastPage) {
            this.setState(prevState => ({ startPage: prevState.startPage + 1 }))
        }

        updateContent(currentPage + 1)
        this.setState(prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }))
    }

    render() {
        const { lastPage, pagesList, light } = this.props
        const { currentPage, startPage } = this.state

        const lightPag = light ? 'light-pag' : ''
        const disablePagLeftClass = currentPage === 1 ? 'disable-pag' : ''
        const disablePagRightClass = currentPage === lastPage ? 'disable-pag' : ''
        const activePageClass = 'current-page'

        return (
            <div className="pagination">
                <div
                    className={`pagination__left ${lightPag} ${disablePagLeftClass}`}
                    onClick={this.decPage}
                >
                    <div className={`pagination__left__arrow ${lightPag}`} />
                </div>
                {pagesList.slice(startPage - 1, startPage + 2).map((item, i) => (
                    <div
                        className={`pagination__page ${lightPag} ${
                            currentPage === item ? activePageClass : ''
                        }`}
                        key={i}
                    >
                        <div
                            className={`pagination__page__number ${lightPag}`}
                            id={`${item}`}
                            onClick={this.pageClick}
                        >
                            {item}
                        </div>
                    </div>
                ))}
                <div
                    className={`pagination__right ${lightPag} ${disablePagRightClass}`}
                    onClick={this.incPage}
                >
                    <div className={`pagination__right__arrow ${lightPag}`} />
                </div>
            </div>
        )
    }
}

Pagination.propTypes = {
    lastPage: PropTypes.number.isRequired,
    pagesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    updateContent: PropTypes.func.isRequired,
    light: PropTypes.bool
}

Pagination.defaultProps = {
    light: false
}

export { Pagination }
