import _ from 'lodash'

import { AssociationGroup } from './AssociationGroup'
import { Company } from './Company'
import { ImageItem } from './ImageItem'

class Member {
    constructor({
        id,
        first_name,
        last_name,
        title,
        position,
        company,
        association_group,
        phone_numbers,
        fax_numbers,
        mobile_numbers,
        biography,
        emails,
        image
    }) {
        this.id = id
        this.name = first_name
        this.surname = last_name
        this.title = title
        this.position = position
        this.company = company && new Company(company)
        this.associationGroup = association_group && new AssociationGroup(association_group)
        this.phoneNumbers = phone_numbers
        this.faxNumbers = fax_numbers
        this.biography = biography
        this.mobileNumbers = mobile_numbers
        this.emails = emails
        this.image = image && new ImageItem(image)
    }
    get originalImage() {
        return this.image && this.image.image.original
    }

    get resizedImage() {
        return this.image && this.image.image.resized
    }

    get thumbnailImage() {
        return this.image && this.image.image.thumbnail
    }

    get contactImage() {
        return this.originalImage || '/images/placeholder-contact.png'
    }

    get hasHtmlBiography() {
        return !_.isEmpty(this.biography)
    }
    get htmlBiography() {
        return (
            this.hasHtmlBiography && {
                __html: this.biography
            }
        )
    }
}

export { Member }
