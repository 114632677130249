import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import SearchButton from './SearchButton/SearchButton'
import { Modal } from '../../Modal/Modal'
import SearchModalView from './SearchModalView/SearchModalView'

import './Search.css'

class Search extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSearchOpen: false
        }
    }

    addScrollLock() {
        document.querySelector('html').classList.add('page-lock-scroll')
    }

    removeScrollLock() {
        document.querySelector('html').classList.remove('page-lock-scroll')
    }

    onOpenSearch = () => {
        this.addScrollLock()
        this.setState({ isSearchOpen: true })
    }

    onCloseSearch = e => {
        if (e.target.getAttribute('data-responsibility') === 'close-modal') {
            this.removeScrollLock()
            this.setState({ isSearchOpen: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.removeScrollLock()
            this.setState({ isSearchOpen: false })
        }
    }

    render() {
        const { isSearchOpen } = this.state

        return (
            <div className="search">
                <SearchButton onOpenSearch={this.onOpenSearch} />
                <Modal isOpen={isSearchOpen} onClose={this.onCloseSearch}>
                    <SearchModalView currentPage={this.props.location.pathname} />
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Search))
