import React from 'react'

import { withTranslation } from 'react-i18next'

import './SearchButton.css'

const SearchButton = ({ onOpenSearch, t }) => (
    <div className="search-button">
        <div className="search-button__btn fas fa-search" onClick={onOpenSearch} />
    </div>
)

export default withTranslation()(SearchButton)
