import React from 'react'
import { Link } from 'react-router-dom'

import './SingleActivityListItem.css'

const SingleActivityListItem = props => {
    const calcMonth = month => {
        let lang = JSON.parse(localStorage.getItem('language'))
        switch (month) {
            case '01':
                return lang ? 'januar' : 'јануар'
                break
            case '02':
                return lang ? 'februar' : 'фебруар'
                break
            case '03':
                return lang ? 'mart' : 'март'
                break
            case '04':
                return lang ? 'april' : 'април'
                break
            case '05':
                return lang ? 'maj' : 'мај'
                break
            case '06':
                return lang ? 'jun' : 'јун'
                break
            case '07':
                return lang ? 'jul' : 'јул'
                break
            case '08':
                return lang ? 'avgust' : 'август'
                break
            case '09':
                return lang ? 'septembar' : 'септембар'
                break
            case '10':
                return lang ? 'oktobar' : 'октобар'
                break
            case '11':
                return lang ? 'novembar' : 'новембар'
                break
            case '12':
                return lang ? 'decembar' : 'децембар'
                break
            default:
                return lang ? 'mesec' : 'месец'
        }
    }

    const calcHumanReadDate = date => {
        const dateSplited = date.split(' ')[0].split('-')

        return `${dateSplited[2]}. ${calcMonth(dateSplited[1])} ${dateSplited[0]}.`
    }

    return (
        <div className="single-activity-list-item-container">
            <div className="single-activity-list-item-wrapper">
                <Link to={`open-balkan-aktivnost/${props.data.title_slug}`}>
                    <img
                        className="single-activity-featured-image"
                        src={props.data.headline_image.image.original}
                        alt="aktuelnost-img"
                    />
                    <div className="single-activity-title-card-wrapper">
                        <h3 className="single-activity-title-card-title">{props.data.title}</h3>
                        <div className="single-activity-title-card-date-wrapper">
                            <span className="single-activity-title-card-date-horizontal-line" />
                            <p className="single-activity-title-card-date">
                                {calcHumanReadDate(props.data.publish_date)}
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default SingleActivityListItem
