import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { SectionTitle } from '../SectionTitle/SectionTitle'

import './Modal.css'

const InnerModal = ({ isOpen, title = '', onClose, children }) =>
    isOpen ? (
        <div aria-modal="true" role="dialog" aria-label={title}>
            <div className="modal__background" onClick={onClose} data-responsibility="close-modal">
                <div role="dialog" className="modal__dialog">
                    <div className="modal__dialog__header">
                        <div
                            className="modal__dialog__header__close"
                            data-responsibility="close-modal"
                        />
                        {title && (
                            <div className="modal__dialog__header__title section-title-centered">
                                <SectionTitle title={title} underlineClass />
                            </div>
                        )}
                    </div>
                    <div className="modal__dialog__body__content">{children}</div>
                </div>
            </div>
        </div>
    ) : null

function Modal(props) {
    return ReactDOM.createPortal(<InnerModal {...props} />, document.querySelector('#root-modal'))
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node
}

export { Modal }
