import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { News } from '../../../entities/News'

import './AllNewsItem.css'

const AllNewsItem = ({ newsItem, dark = false }) => {
    const { slug, allNewsImage, truncatedTitle, displayDate, truncatedHtmlIntro } = newsItem

    const className = dark ? '--dark' : ''
    return (
        <div className={`all-news-item all-news-item${className}`}>
            <Link to={`/vesti/${slug}`}>
                <div
                    className="all-news-item__image"
                    style={{ backgroundImage: `url("${allNewsImage}")` }}
                />
                <div className="all-news-item__content">
                    <h5>
                        <span>{truncatedTitle}</span>
                    </h5>
                    <h6>
                        <span>{displayDate}</span>
                    </h6>
                    <p dangerouslySetInnerHTML={truncatedHtmlIntro} />
                </div>
            </Link>
        </div>
    )
}

AllNewsItem.propTypes = {
    newsItem: PropTypes.instanceOf(News).isRequired
}

export { AllNewsItem }
