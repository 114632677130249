import React from 'react'
import PropTypes from 'prop-types'

import './SearchNoResults.css'

const SearchNoResults = ({ noResultsText }) => (
    <div className="search-no-results">
        <div className="search-no-results__icon fas fa-search" />
        <h6 className="search-no-results__text">{noResultsText}</h6>
    </div>
)

export default SearchNoResults

SearchNoResults.propTypes = {
    noResultsText: PropTypes.string
}
