import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { News } from '../../../../../../entities/News'

import './RCAllNewsItem.css'

const RCAllNewsItem = ({ newsItem }) => {
    const { slug, allNewsImage, truncatedTitle, displayDate, truncatedHtmlIntro } = newsItem
    return (
        <div className="rc-all-news-item">
            <Link to={`/vesti/${slug}`}>
                {allNewsImage && (
                    <div
                        className="rc-all-news-item__image"
                        style={{ backgroundImage: `url("${allNewsImage}")` }}
                    />
                )}
                <div className="rc-all-news-item__content">
                    <h5>
                        <span>{truncatedTitle}</span>
                    </h5>
                    <h6>
                        <span>{displayDate}</span>
                    </h6>
                    <p dangerouslySetInnerHTML={truncatedHtmlIntro} />
                </div>
            </Link>
        </div>
    )
}

RCAllNewsItem.propTypes = {
    newsItem: PropTypes.instanceOf(News).isRequired
}

export { RCAllNewsItem }
