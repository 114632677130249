import 'react-app-polyfill/ie9'
import 'core-js'

import '../src/config/i18n'
import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter } from 'react-router-dom'

// Time formating setup
import 'dayjs/locale/sr'
import 'dayjs/locale/sr-cyrl'
import dayjs from 'dayjs'

import ScrollToTop from './view/components/ScrollToTop'
import App from './view/App/App'

import { languageService } from './services/languageService'

import './index.css'

// use loaded locale globally
dayjs.locale(languageService.getLocaleLang())

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root')
)

registerServiceWorker()
