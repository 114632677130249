import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { languageService } from '../../../services/languageService'

import { HeaderNav } from './HeaderNav/HeaderNav'
import { HamburgerNavigationList } from './HamburgerNavigationList/HamburgerNavigationList'
import Search from './Search/Search'

import './Header.css'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            langLat: languageService.getActiveLanguage('language') ? true : false
        }

        this.onLogoClick = this.onLogoClick.bind(this)
        this.onLanguageSelectorClick = this.onLanguageSelectorClick.bind(this)
    }

    addScrollLockMobile() {
        document.querySelector('html').classList.add('page-lock-scroll-header')
    }

    removeScrollLockMobile() {
        document.querySelector('html').classList.remove('page-lock-scroll-header')
    }

    onOpenDropdown = () => {
        this.setState({ isOpen: true })
        this.addScrollLockMobile()
    }

    onCloseDropdown = () => {
        this.setState({ isOpen: false })
        this.removeScrollLockMobile()
    }

    onLogoClick(e) {
        this.state.isOpen && this.onCloseDropdown()
    }

    onLanguageSelectorClick(e) {
        if (e.target.classList.value === 'active-language') {
            this.setState({ isOpen: false })
            return
        }
        const active = this.state.langLat ? '' : 'sr_lat'
        languageService.setActiveLanguage('language', active)
        this.setState(prevState => ({ ...prevState, langLat: !prevState.langLat }))
        window.location.reload()
    }

    showHeaderOnScrollUp() {
        const mainHeader = document.querySelector('.header')
        let scrolling = false,
            previousTop = 0,
            scrollDelta = 10,
            scrollOffset = 150

        function checkSimpleNavigation(currentTop) {
            if (previousTop - currentTop > scrollDelta) {
                mainHeader.classList.remove('header--hidden')
            } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
                mainHeader.classList.add('header--hidden')
            }
        }

        function autoHideHeader() {
            var currentTop = window.pageYOffset

            checkSimpleNavigation(currentTop)

            previousTop = currentTop
            scrolling = false
        }

        window.onscroll = () => {
            if (!scrolling) {
                scrolling = true
                !window.requestAnimationFrame
                    ? setTimeout(autoHideHeader, 250)
                    : requestAnimationFrame(autoHideHeader)
            }
        }
    }

    componentDidMount() {
        this.showHeaderOnScrollUp()
    }

    render() {
        const { t } = this.props
        const menuNavigation = this.state.isOpen ? 'open' : 'close'
        const { langLat } = this.state
        const logoImg = langLat ? '/images/logo_lat@2x.png' : '/images/logo_cir@2x.png'

        return (
            <header className="header">
                <div className="container-1280">
                    <NavLink to="/">
                        <div className="header__logo" onClick={this.onLogoClick}>
                            <img src={logoImg} alt="logo" />
                        </div>
                    </NavLink>
                    <div className="header__navigation">
                        <HeaderNav navigation={t('header.navigationList')} />
                    </div>
                    <div className="header__content">
                        <a href="/strana/sekcija/biznis-info-servis-aplikacija">
                            Call center <span>0800 808 809</span>
                        </a>
                        <ul className="header__content__options">
                            <li
                                onClick={this.onLanguageSelectorClick}
                                className={langLat ? '' : 'active-language'}
                            >
                                ћир
                            </li>
                            <li
                                onClick={this.onLanguageSelectorClick}
                                className={langLat ? 'active-language' : ''}
                            >
                                lat
                            </li>
                            <a href="https://en.pks.rs/" target="_blank" rel="noopener noreferrer">
                                <li>eng</li>
                            </a>
                        </ul>
                    </div>
                    <div className="header__search-menu">
                        <div className={`header__menu header__menu--${menuNavigation}`}>
                            <img
                                src="/images/hamburger.png"
                                alt="menu"
                                className="header__menu__hamburger"
                                onClick={this.onOpenDropdown}
                            />
                            <img
                                src="/images/close-menu.png"
                                alt="menu"
                                className="header__menu__close-menu"
                                onClick={this.onCloseDropdown}
                            />
                        </div>
                        <Search />
                    </div>
                </div>
                {this.state.isOpen && (
                    <HamburgerNavigationList
                        navigation={t('header.navigationList')}
                        onLanguageSelectorClick={this.onLanguageSelectorClick}
                        onCloseDropdown={this.onCloseDropdown}
                        langLat={langLat}
                    />
                )}
            </header>
        )
    }
}

Header.propTypes = {
    t: PropTypes.func
}

export { Header }
export default withTranslation()(Header)
