import dayjs from 'dayjs'
import _ from 'lodash'

import { ImageItem } from './ImageItem'
import { Participator } from './Participator'
import { IdNameEntity } from './IdNameEntity'
import { DocumentFile } from './DocumentFile'
import { Municipality } from './Municipality'

import { PKS_EVENTS } from '../config/pks-constants'

class EventCountry {
    constructor({ id, name }) {
        this.id = id
        this.countryName = name
    }
}

class Event {
    constructor({
        id,
        title,
        slug,
        content_short,
        content,
        event_category,
        start_date,
        end_date,
        sector,
        department,
        not_on_website,
        video_link,
        media_contact,
        headline_image,
        participants_contact,
        documents,
        country,
        municipality
    }) {
        this.id = id
        this.title = title
        this.eventSlug = slug
        this.contentShort = content_short
        this.content = content
        this.category = event_category ? new IdNameEntity(event_category) : null
        this.sector = sector ? new IdNameEntity(sector) : null
        this.department = department ? new IdNameEntity(department) : null
        this.published = not_on_website
        this.startDate = start_date
        this.endDate = end_date
        this.videoLink = video_link
        this.mediaContact = media_contact && new Participator(media_contact)
        this.participantsContact = participants_contact && new Participator(participants_contact)
        this.headlineImage = headline_image ? new ImageItem(headline_image) : null
        this.documents = documents ? documents.map(doc => new DocumentFile(doc)) : []
        this.country = country && new EventCountry(country)
        this.municipality = municipality && new Municipality(municipality)
    }

    // FIXME: KEEP EITHER NAME OR TITLE
    get name() {
        return this.title
    }

    get eventDescription() {
        const temporalDIV = document.createElement('div')
        temporalDIV.innerHTML = this.contentShort

        return temporalDIV.textContent || ''
    }

    get slug() {
        return this.eventSlug || this.fallbackSlug
    }

    get fallbackSlug() {
        const titleSlug = this.title
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
        const slug = `${titleSlug}--${this.id}`
        return slug
    }

    get htmlContentShort() {
        return {
            __html: this.contentShort
        }
    }

    get htmlContentShortTruncated() {
        const truncateOptions = {
            length: 80,
            separator: ' '
        }

        return {
            __html: _.truncate(this.contentShort, truncateOptions)
        }
    }

    get htmlContent() {
        return {
            __html: this.content
        }
    }

    get displayCategory() {
        return this.category.name
    }

    get displayDateAndTime() {
        const displayDate = dayjs(this.startDate).format('dddd, DD. MMMM YYYY. HH:mm')

        return displayDate
    }

    get start() {
        const parsedStartDate = dayjs(this.startDate).toString()
        return new Date(parsedStartDate)
    }

    get end() {
        const parsedEndDate = dayjs(this.endDate).toString()
        return new Date(parsedEndDate)
    }

    get originalImage() {
        return this.headlineImage ? this.headlineImage.originalSize : null
    }

    get eventImageLarge() {
        return this.headlineImage && this.headlineImage.eventHeadline
    }

    get eventImage() {
        return this.eventImageLarge || this.originalImage
    }

    get eventThumbnailImage() {
        return this.headlineImage && this.headlineImage.mediumThumbnail
    }

    get eventThumbnail() {
        return this.eventThumbnailImage || this.originalImage
    }

    get eventImageSource() {
        return this.headlineImage ? this.headlineImage.source : ''
    }

    get displayDate() {
        const startDate = dayjs(this.startDate).format('DD.MM.YYYY. HH:mm')
        return `${startDate}`
    }

    get contacts() {
        return [this.mediaContact, this.participantsContact].filter(contact => !!contact.fullName)
    }

    get hasDocuments() {
        return !_.isEmpty(this.documents)
    }

    get hasContacts() {
        return !_.isEmpty(this.contacts)
    }

    get truncatedTitle() {
        return _.truncate(this.title, {
            length: 100,
            separator: ' '
        })
    }
    get truncatedTitleMobile() {
        return _.truncate(this.title, {
            length: 60,
            separator: ' '
        })
    }

    get backgroundColor() {
        switch (this.category.id) {
            case PKS_EVENTS.FAIR:
                return 'rgb(255, 204, 51)'
            case PKS_EVENTS.FORUM:
                return 'rgb(102, 204, 51)'
            case PKS_EVENTS.SEMINAR:
                return 'rgb(102, 204, 204)'
            default:
                return 'rgb(234,105,105)'
        }
    }

    get locationAddress() {
        return `${this.municipality.name}, ${this.country.countryName}`
    }

    getFileIcon(isActive = false) {
        return isActive ? '/images/red.png' : '/images/blue.png'
    }
}

export { Event }
