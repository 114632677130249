import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Event } from '../../../entities/Event'

import './DateEventItem.css'

const DateEventItem = ({ event, dark }) => {
    const className = dark ? 'dark' : ''

    return (
        <Link
            to={`/dogadjaj/${event.slug}`}
            className={`date-event-item date-event-item--${className}`}
        >
            <div className="date-event-item__info">
                <div className="date-event-item__info__date">{event.displayDate}</div>
                <div
                    className="date-event-item__info__label"
                    style={{ backgroundColor: event.backgroundColor }}
                >
                    <div>{event.displayCategory}</div>
                </div>
            </div>
            <div className="date-event-item__title">{event.title}</div>
        </Link>
    )
}

DateEventItem.propTypes = {
    event: PropTypes.instanceOf(Event)
}

export { DateEventItem }
