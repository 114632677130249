import React from 'react'

import './FooterInfo.css'

const FooterInfo = ({ info }) => {
    return (
        <div className="footer-info">
            <div>
                <h5>{info.name}</h5>
            </div>
            <div>
                <h5>{info.address.street}</h5>
                <h5>{info.address.city}</h5>
            </div>
            <div>
                <h5>{info.contact.phone}</h5>
                <h5>
                    e-mail:{' '}
                    <a className="footer-info__email" href={`mailto:${info.contact.email}`}>
                        {info.contact.email}
                    </a>
                </h5>
            </div>
            <div>
                <h5>
                    {info.idNum.pib.title} {info.idNum.pib.number}
                </h5>
                <h5>{info.idNum.matNum}</h5>
            </div>
        </div>
    )
}

export { FooterInfo }
