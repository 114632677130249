import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import i18n from '../../../config/i18n'
import { FcPrevious, FcNext } from 'react-icons/fc'
import SingleActivityListItem from './SingleActivityListItem/SingleActivityListItem'
import './OpenBalkan.css'

const OpenBalkan = ({
    aktuelnostiTitle = i18n.t('openBalkan.aktuelnostiTitle'),
    mainSubtitle = i18n.t('openBalkan.mainSubtitle'),
    introTxt1 = i18n.t('openBalkan.introTxt1'),
    introTxt2 = i18n.t('openBalkan.introTxt2'),
    introTxt3 = i18n.t('openBalkan.introTxt3'),
    documentsTitle = i18n.t('openBalkan.documentsTitle'),
    ucesniciTitle = i18n.t('openBalkan.ucesniciTitle')
}) => {
    const [aktuelnosti, setAktuelnosti] = useState([])
    const [aktuelnostiToShow, setAktuelnostiToShow] = useState([])
    const [aktuelnostiPaginationNumberSelected, setAktuelnostiPaginationNumberSelected] = useState(
        1
    )
    const [aktuelnostiPaginationMaxNumber, setAktuelnostiPaginationMaxNumber] = useState(1)
    const [sections, setSections] = useState([])

    useEffect(() => {
        let lang = JSON.parse(localStorage.getItem('language'))

        fetch('https://api.pks.rs/public-api/open-balkan/aktuelnosti?offset=100', {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())

            .then(data => {
                setAktuelnosti(data.body.entries)
                setAktuelnostiToShow(data.body.entries.slice(0, 6))
                setAktuelnostiPaginationMaxNumber(Math.ceil(data.body.entries.length / 6))
            })

        fetch('https://api.pks.rs/public-api/open-balkan/sections', {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(data => setSections(data.body.entries))
    }, [])

    const mapAktuelnosti = () => {
        return aktuelnostiToShow.map((aktuelnost, index) => {
            return (
                <SingleActivityListItem data={aktuelnost} key={`single-activity-item-${index}`} />
            )
        })
    }
    const mapSections = () => {
        return sections.map((section, index) => {
            return (
                <Link
                    to={`/open-balkan-sporazumi/${section.slug}`}
                    key={`open-balkan-section-${index}`}
                    className="open-balkan-sporazumi-wrapper"
                >
                    <p>{section.title}</p>
                    <span className="arrow-link-list-item__arrow" />
                </Link>
            )
        })
    }
    const selectPaginationNumber = data => {
        let sliceDataStart = null

        if (data == 1) {
            sliceDataStart = 1
        } else {
            sliceDataStart = (data - 1) * 6
        }

        setAktuelnostiPaginationNumberSelected(data)
        setAktuelnostiToShow(aktuelnosti.slice(sliceDataStart, sliceDataStart + 6))
        document.getElementById('aktuelnosti-scroll-target').scrollIntoView({ behavior: 'smooth' })
    }

    const mapPaginationNumbers = () => {
        const output = []
        for (let i = 0; i < Math.ceil(aktuelnosti.length / 6); i++) {
            output.push(
                <div
                    key={`pagination-number${i}`}
                    onClick={() => selectPaginationNumber(i + 1)}
                    className={`open-balkan-pagination-number ${
                        aktuelnostiPaginationNumberSelected == i + 1
                            ? 'open-balkan-selected-pagination-number'
                            : ''
                    }`}
                >
                    {(i + 1).toString()}
                </div>
            )
        }
        return output
    }

    return (
        <main>
            <div className="open-balkan-top-banner" />
            <div className="section-container container-1280">
                <h1 className="top-section-title__text dark top-section-title__text--underlined">
                    {mainSubtitle}
                </h1>
                <p className="top-section-title__text dark open-balkan-subtitle">{}</p>

                <p className="open-balkan-regular-txt">{introTxt1}</p>
                <p className="open-balkan-regular-txt">{introTxt2}</p>
                <p className="open-balkan-regular-txt">{introTxt3}</p>

                <h2
                    id="aktuelnosti-scroll-target"
                    className="top-section-title__text dark top-section-title__text--underlined"
                >
                    {aktuelnostiTitle}
                </h2>

                <div className="open-balkan-section-wrapper">{mapAktuelnosti()}</div>

                <div className="open-balkan-paggination-wrapper">
                    <FcPrevious
                        size={24}
                        className={`${
                            aktuelnostiPaginationNumberSelected > 1
                                ? 'open-balkan-pagination-arrows-hover-effect'
                                : 'open-balkan-pagination-arrow-hide-it'
                        }`}
                        onClick={
                            aktuelnostiPaginationNumberSelected > 1
                                ? () =>
                                      selectPaginationNumber(
                                          aktuelnostiPaginationNumberSelected - 1
                                      )
                                : ''
                        }
                    />
                    <div className="open-balkan-pagination-numbers-wrapper">
                        {mapPaginationNumbers()}
                    </div>
                    <FcNext
                        size={24}
                        className={`${
                            aktuelnostiPaginationNumberSelected < aktuelnostiPaginationMaxNumber
                                ? 'open-balkan-pagination-arrows-hover-effect'
                                : 'open-balkan-pagination-arrow-hide-it'
                        }`}
                        onClick={
                            aktuelnostiPaginationNumberSelected < aktuelnostiPaginationMaxNumber
                                ? () =>
                                      selectPaginationNumber(
                                          aktuelnostiPaginationNumberSelected + 1
                                      )
                                : ''
                        }
                    />
                </div>

                <h2 className="top-section-title__text dark top-section-title__text--underlined open-balkan-mrgtop">
                    {documentsTitle}
                </h2>

                <div className="open-balkan-section-wrapper">
                    {mapSections()}
                    <Link
                        to="/open-balkan-ucesnici"
                        key={`open-balkan-section-ucesnici`}
                        className="open-balkan-sporazumi-wrapper"
                    >
                        <p>{ucesniciTitle}</p>
                        <span className="arrow-link-list-item__arrow" />
                    </Link>
                </div>
            </div>
        </main>
    )
}

export default OpenBalkan
