import { News } from '../entities/News'
import { PaginationInfo } from '../entities/PaginationInfo'

import { pksApiService } from '../services/pksApiService'
import { NewsCategory } from '../entities/NewsCategory'
import { PKS_NEWS_CATEGORY } from '../config/pks-constants'

const includeFields = [
    'id',
    'title',
    'antrfile',
    'content_short',
    'headline_image',
    'created_at',
    'publish_date',
    'title_slug'
]
    .map(field => `include[]=${field}`)
    .join('&')

class NewsCommunicator {
    /**
     * News displayed on homepage. News is displayed on homepage if
     * it's in marked_news: 1 and it's in section Naslovna
     */
    async fetchHomeFeaturedNews() {
        const featuredNewsEndpoint = `/news?${includeFields}`

        const queryParams = {
            marked_news: 1,
            news_category_id: PKS_NEWS_CATEGORY.NEWS_HOME_CATEGORY,
            offset: 1,
            approved: 1
        }
        const featuredNews = await pksApiService.fetchData(featuredNewsEndpoint, queryParams)

        return featuredNews.map(item => new News(item))
    }

    async fetchHomeSliderNews() {
        const featuredNewsEndpoint = `/news?${includeFields}`

        const queryParams = {
            news_category_id: PKS_NEWS_CATEGORY.NEWS_HOME_CATEGORY,
            marked_news: 0,
            offset: 10,
            approved: 1
        }
        const featuredNews = await pksApiService.fetchData(featuredNewsEndpoint, queryParams)

        return featuredNews.map(item => new News(item))
    }

    async fetchNewsCategories() {
        const newsCategoriesEndpoint = '/news-categories'

        const newsCategories = await pksApiService.fetchData(newsCategoriesEndpoint)

        return newsCategories.map(item => new NewsCategory(item))
    }

    async fetchTopNews(queryParam = '', id = null, withApproval = false) {
        const queryParams = {
            [queryParam]: id,
            marked_news: 1,
            offset: 3
        }

        const withApprovalParams = {
            [queryParam]: id,
            marked_news: 1,
            offset: 3,
            approved: 1
        }

        const params = withApproval ? withApprovalParams : queryParams

        const markedNewsEndpoint = `/news?${includeFields}`
        const markedNews = await pksApiService.fetchData(markedNewsEndpoint, params)

        return markedNews.map(item => new News(item))
    }

    async fetchAllNews(
        queryParam = '',
        id = null,
        currentPage = 1,
        excludeMarkedNews = false,
        withApproval = false
    ) {
        const queryParams = {
            [queryParam]: id,
            page: currentPage,
            marked_news: 0,
            offset: 6
        }

        const withApprovalParams = {
            [queryParam]: id,
            page: currentPage,
            marked_news: excludeMarkedNews ? 0 : null,
            offset: 6,
            approved: 1
        }

        const params = withApproval ? withApprovalParams : queryParams

        const newsEndpoint = `/news?${includeFields}`
        const responseBody = await pksApiService.fetchDataWithMeta(newsEndpoint, params)

        const pagination = new PaginationInfo(responseBody.pagination)
        const news = responseBody.entries.map(item => new News(item))

        return {
            pagination,
            news
        }
    }

    async fetchSingleArticle(id) {
        const articleRequestUrl = `/news/${id}`
        const singleArticle = await pksApiService.fetchData(articleRequestUrl)

        return new News(singleArticle)
    }

    async fetchRelatedNews(article) {
        const { association, sector, categories } = article

        const categoryParams = categories
            .map(category => `news_category_id=${category.id}`)
            .join('&')

        const params = {
            association_id: association ? association.id : null,
            sector_id: sector ? sector.id : null,
            offset: 5
        }

        const relatedNewsEndpoint = `/news?${includeFields}&${categoryParams}`

        const relatedNews = await pksApiService.fetchData(relatedNewsEndpoint, params)

        return relatedNews
            .filter(newsItem => newsItem.id !== article.id)
            .map(item => new News(item))
    }

    async fetchRCRelatedNews(rcId, article) {
        const params = {
            regional_chamber_id: rcId ? rcId : null,
            offset: 5
        }

        const relatedNewsEndpoint = `/news?${includeFields}`

        const relatedNews = await pksApiService.fetchData(relatedNewsEndpoint, params)

        return relatedNews
            .filter(newsItem => newsItem.id !== article.id)
            .map(item => new News(item))
    }

    async fetchRCNewsOnMainSite(currentPage, offset = 4) {
        const params = {
            page: currentPage,
            news_category_id: PKS_NEWS_CATEGORY.REGIONAL_CHAMBERS,
            approved: 1,
            offset: offset
        }

        const rcNewsEndpoint = `/news?${includeFields}`
        const responseBody = await pksApiService.fetchDataWithMeta(rcNewsEndpoint, params)

        const pagination = new PaginationInfo(responseBody.pagination)
        const rcNews = responseBody.entries.map(item => new News(item))

        return {
            pagination,
            rcNews
        }
    }

    async fetchRCNews(id, currentPage, offset = 4) {
        const params = {
            page: currentPage,
            regional_chamber_id: id,
            marked_news: 0,
            offset: offset
        }

        const rcNewsEndpoint = `/news?${includeFields}`
        const responseBody = await pksApiService.fetchDataWithMeta(rcNewsEndpoint, params)

        const pagination = new PaginationInfo(responseBody.pagination)
        const rcNews = responseBody.entries.map(item => new News(item))

        return {
            pagination,
            rcNews
        }
    }

    async fetchFeaturedRCNews(id, offset = 3) {
        const params = {
            regional_chamber_id: id,
            marked_news: 1,
            offset: offset
        }

        const rcNewsEndpoint = `/news?${includeFields}`

        const rcNews = await pksApiService.fetchData(rcNewsEndpoint, params)

        return rcNews.map(item => new News(item))
    }

    fetchPage(pageSize = 20, page = 1, queryParams = {}) {
        return pksApiService
            .fetchPage('/news', pageSize, page, queryParams)
            .then(newsList => newsList.map(item => new News(item)))
    }

    fetchInfo(queryParams = {}) {
        return pksApiService
            .fetchInfo('/news', queryParams)
            .then(newsInfo => new PaginationInfo(newsInfo))
    }
}

export const newsCommunicator = new NewsCommunicator()
