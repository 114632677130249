import React from 'react'

import i18n from '../../config/i18n'

import { SLUGS } from '../../config/pks-constants'

import { RegionalRoutes } from '../Routes'
import { RCHeader } from './RCHeader/RCHeader'
import RCFooter from './RCFooter/RCFooter'
import HelmetComponent from '../components/HelmetComponent/HelmetComponent'

const RegionalChamber = () => {
    const [chamber] = window.location.hostname.split('.')
    const slug = SLUGS[chamber]

    const chamberInfo = i18n.t('chamberInfo')[slug]

    return (
        <>
            <HelmetComponent title={chamberInfo.name} description={chamberInfo.about} />
            <RCHeader slug={slug} />
            <RegionalRoutes />
            <RCFooter slug={slug} />
        </>
    )
}
export { RegionalChamber }
