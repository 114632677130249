import React from 'react'

import { withTranslation } from 'react-i18next'

import { SectionTitle } from '../SectionTitle/SectionTitle'

import './ErrorSection.css'

const ErrorSection = ({ t }) => {
    const onReload = () => {
        window.location.reload()
    }

    return (
        <section className="error-section gray-bg">
            <div className="error-section__title section-title-centered">
                <SectionTitle title={t('errorSection.title')} underlineClass />
            </div>
            <p className="error-section__text">{t('errorSection.text')}</p>
            <div className="error-section__reload" onClick={onReload}>
                {t('errorSection.btnText')}
            </div>
        </section>
    )
}

export default withTranslation()(ErrorSection)
