import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'

import { searchCommunicator } from '../../../../../communicators/searchCommunicator'

import { TopSectionTitle } from '../../../TopSectionTitle/TopSectionTitle'
import { SectionButton } from '../../../SectionButton/SectionButton'
import { SearchResultsView } from './SearchResultsView/SearchResultsView'

import './SearchModalView.css'
import SearchNoResults from './SearchNoResults/SearchNoResults'

class SearchModalView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchResults: [],
            searchTerm: '',
            pagesList: [],
            lastPage: 1,
            title: '',
            noResults: '',
            message: ''
        }
    }

    isEventsPage() {
        const { currentPage } = this.props
        const pagePathnameArr = currentPage.split('/')

        return pagePathnameArr.includes('dogadjaji') || pagePathnameArr.includes('dogadjaj')
            ? true
            : false
    }

    setTitle = () => {
        const { t } = this.props
        if (this.isEventsPage()) {
            this.setState({
                title: t('header.searchEventsTitle')
            })
        } else {
            this.setState({
                title: t('header.searchNewsTitle')
            })
        }
    }

    componentDidMount() {
        this.setTitle()
    }

    async loadSearchResults(page) {
        try {
            const { t } = this.props
            const { searchTerm } = this.state

            if (this.isEventsPage()) {
                const searchResponse = await searchCommunicator.searchEvents(searchTerm, page)

                const { events, pagination } = searchResponse

                this.setState({
                    searchResults: events,
                    pagesList: this.getPages(pagination.totalPages),
                    lastPage: pagination.totalPages,
                    noResults: events.length === 0 ? t('header.searchNoResults') : '',
                    message: ''
                })
            } else {
                const searchResponse = await searchCommunicator.searchNews(searchTerm, page)

                const { news, pagination } = searchResponse

                this.setState({
                    searchResults: news,
                    pagesList: this.getPages(pagination.totalPages),
                    lastPage: pagination.totalPages,
                    noResults: news.length === 0 ? t('header.searchNoResults') : '',
                    message: ''
                })
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    updateSearchResults = currentPaginationPage => {
        this.loadSearchResults(currentPaginationPage)
    }

    getPages(n) {
        const array = []
        for (let i = 1; i <= n; i++) {
            array.push(i)
        }

        return array
    }

    handleChange = e => {
        this.setState({
            searchTerm: e.target.value
        })
    }

    onSearch = e => {
        const { t } = this.props
        if (
            e.keyCode === 13 ||
            e.currentTarget.parentElement.classList.contains('section-button')
        ) {
            this.state.searchTerm.length > 1
                ? this.loadSearchResults(1)
                : this.setState({ message: t('header.searchMessage') })
        }
    }

    render() {
        const { t } = this.props
        const {
            searchTerm,
            searchResults,
            pagesList,
            lastPage,
            title,
            noResults,
            message
        } = this.state

        return (
            <div className="search-modal-view">
                <TopSectionTitle centered underlined title={title} dark />
                <div className="search__search">
                    <input
                        type="text"
                        placeholder={t('header.search')}
                        className="search__search__bar"
                        value={searchTerm}
                        onChange={this.handleChange}
                        onKeyDown={this.onSearch}
                    />
                    <SectionButton
                        buttonText={t('header.searchButton')}
                        onClickHandler={this.onSearch}
                    />
                </div>
                <SearchResultsView
                    searchResults={searchResults}
                    lastPage={lastPage}
                    pagesList={pagesList}
                    updateSearchResults={this.updateSearchResults}
                    message={message}
                />
                {noResults && <SearchNoResults noResultsText={noResults} />}
            </div>
        )
    }
}

export default withTranslation()(SearchModalView)
