import React from 'react'

import { withTranslation } from 'react-i18next'

import { RCPage } from '../../RCPage/RCPage'
import { RCSectionBlock } from '../../RCSectionBlock/RCSectionBlock'

import { DataProtection } from '../../../components/DataProtection/DataProtection'
import { RCPageInfo } from '../RCWebPage/RCPageInfo/RCPageInfo'

const RCContact = ({ t, slug }) => {
    const chamberInfo = t('chamberInfo')[slug]

    return (
        <RCPage
            // isLoading={isLoading}
            title={`${chamberInfo.name}/${t('dataProtectionTitle')}`}
            description={chamberInfo.about}
        >
            <RCPageInfo
                title={t('dataProtectionTitle')}
                bgImage="/images/data-protection.jpg"
                overlay
            />
            <RCSectionBlock withContainer>
                <DataProtection text={t('dataProtectionText')} />
            </RCSectionBlock>
        </RCPage>
    )
}

export default withTranslation()(RCContact)
