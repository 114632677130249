import { PKS_NEWS_CATEGORIES } from './pks-constants'

const getTwoDigitNumber = num => (num < 10 ? `0${num}` : num)

export const getDate = date => {
    const dayNum = new Date(date).getDate()
    const day = getTwoDigitNumber(dayNum)

    const monthNum = new Date(date).getMonth() + 1
    const monthTwoDigit = getTwoDigitNumber(monthNum)

    const year = new Date(date).getFullYear()

    return `${year}-${monthTwoDigit}-${day}`
}

const endDayCurrentWeek = () => {
    const day = new Date().getDate()
    return day + 7 - new Date().getDay()
}

const replaceDay = day => {
    const currentDate = getDate(new Date()).split('-')
    const month = parseInt(currentDate[1], 0)
    const year = parseInt(currentDate[0], 0)
    if (month === 2) {
        if (year % 4 === 0 && day > 29) {
            currentDate.splice(2, 1, day - 29)
            currentDate.splice(1, 1, month + 1)
        } else if (day > 28) {
            currentDate.splice(2, 1, day - 28)
            currentDate.splice(1, 1, month + 1)
        } else {
            currentDate.splice(2, 1, day)
        }
    } else if (
        month === 1 ||
        month === 3 ||
        month === 5 ||
        month === 7 ||
        month === 8 ||
        month === 10
    ) {
        if (day > 31) {
            currentDate.splice(2, 1, day - 31)
            currentDate.splice(1, 1, month + 1)
        } else {
            currentDate.splice(2, 1, day)
        }
    } else if (month === 12) {
        if (day > 31) {
            currentDate.splice(2, 1, day - 31)
            currentDate.splice(1, 1, 1)
            currentDate.splice(0, 1, year + 1)
        } else {
            currentDate.splice(2, 1, day)
        }
    } else {
        if (day > 30) {
            currentDate.splice(2, 1, day - 30)
            currentDate.splice(1, 1, month + 1)
        } else {
            currentDate.splice(2, 1, day)
        }
    }
    return currentDate.join('-')
}

export const setDateQueryParam = (offsetDayNum = 0, isStartCurrentWeek = false) => {
    return isStartCurrentWeek ? getDate(new Date()) : replaceDay(endDayCurrentWeek() + offsetDayNum)
}

export const findNewsCategorySlug = id => {
    const newsCategory = Object.entries(PKS_NEWS_CATEGORIES).find(([key, value]) => value.id === id)
    return newsCategory ? newsCategory[1].slug : ''
}

export const findNewsCategoryID = slug => {
    const newsCategory = Object.entries(PKS_NEWS_CATEGORIES).find(
        ([key, value]) => value.slug === slug
    )

    return newsCategory ? newsCategory[1].id : null
}

export const extractDomain = host => {
    let domain = ''
    if (host.split('.')[0] === 'www') {
        domain = host
            .split('.')
            .filter(item => item !== 'www')
            .join('.')
    } else {
        domain = host
    }

    return domain
}

export const formatNumber = number => {
    if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
}
