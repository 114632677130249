// Constants related to BACKEND API
export const PKS_NEWS_CATEGORY = Object.freeze({
    NEWS_HOME_CATEGORY: 1,
    NEWS: 8,
    REGIONAL_CHAMBERS: 9,
    EDUCATION: 2,
    TANJUG: 3,
    FAIRS_NEWS: 18,
    SUCCESSFUL_STORIES: 20
})

export const PKS_NEWS_CATEGORIES = {
    NEWS_HOME_CATEGORY: {
        id: 1,
        slug: 'naslovna'
    },
    TANJUG: {
        id: 7,
        slug: 'tanjugove-vesti'
    },
    REGIONAL_CHAMBERS: {
        id: 9,
        slug: 'rpk-vesti'
    },
    ENTREPRENEURSHIP: {
        id: 10,
        slug: 'sektor-preduzetnistva'
    },
    INTERESTS_REPRESENTATION: {
        id: 11,
        slug: 'zastupanje-interesa'
    },
    EU_INTEGRATION_CENTER: {
        id: 12,
        slug: 'centar-za-eu-integracije'
    },
    INTERNATIONALIZATION: {
        id: 13,
        slug: 'internacionalizacija'
    },
    ISTEN_NEWS: {
        id: 14,
        slug: 'isten-vesti'
    },
    INTER_CONNECT_NEWS: {
        id: 15,
        slug: 'inter-connect-vesti'
    },
    EDUCATION: {
        id: 17,
        slug: 'edukacija'
    },
    FAIRS_NEWS: {
        id: 18,
        slug: 'sajmovi'
    },
    SUCCESSFUL_STORIES: {
        id: 20,
        slug: 'uspesne-price'
    },
    ENVIRONMENT_DEPARTMENT: {
        id: 21,
        slug: 'sluzba-za-zivotnu-sredinu'
    },
    MEDIA_ANNOUNCEMENTS: {
        id: 22,
        slug: 'saopstenje-za-medije'
    },
    MEDIA_INFORMATION: {
        id: 23,
        slug: 'poziv-i-informacije-za-medije'
    },
    DUAL_EDUCATION: {
        id: 24,
        slug: 'dualno-obrazovanje'
    },
    PROJECTS_CENTER: {
        id: 25,
        slug: 'centar-za-upravljanje-projektima'
    },
    MAT: {
        id: 26,
        slug: 'makroekonomske-analize-i-trendovi'
    },
    BAROMETAR: {
        id: 27,
        slug: 'barometar'
    }
}

export const PKS_EVENTS = Object.freeze({
    FAIR: 1,
    FORUM: 2,
    SEMINAR: 3
})

export const PKS_ABOUT_US = Object.freeze({
    PRESIDENT_ID: 1,
    SUPERVISORY_COMMITTEE_ID: 2,
    STEERING_COMMITTEE_ID: 3,
    ASSEMBLY_ID: 4,
    COMMISSION_ID: 5
})

export const PKS_SERVICES = Object.freeze({
    SERVICES_BY_CATEGORIES: 5,
    SERVICES: 2
})

export const PKS_SERVICES_SECTIONS = Object.freeze({
    ABOUT_SERVICES: 'usluge-pks-uvodni-tekst',
    CONTACT_SERVICES: 'usluge-pks-kontakt'
})

export const HOSTNAMES = [
    'beograd.pks.rs',
    'kikinda.pks.rs',
    'kragujevac.pks.rs',
    'kraljevo.pks.rs',
    'krusevac.pks.rs',
    'leskovac.pks.rs',
    'nis.pks.rs',
    'novi-sad.pks.rs',
    'pancevo.pks.rs',
    'pozarevac.pks.rs',
    'sombor.pks.rs',
    'sremska-mitrovica.pks.rs',
    'subotica.pks.rs',
    'uzice.pks.rs',
    'valjevo.pks.rs',
    'zajecar.pks.rs',
    'zrenjanin.pks.rs'
]

export const GA_CODES = {
    'pks.rs': 'UA-137247681-2',
    'beograd.pks.rs': 'UA-137247681-3',
    'kikinda.pks.rs': 'UA-137247681-4',
    'kragujevac.pks.rs': 'UA-137247681-5',
    'kraljevo.pks.rs': 'UA-137247681-6',
    'krusevac.pks.rs': 'UA-137247681-7',
    'leskovac.pks.rs': 'UA-137247681-8',
    'nis.pks.rs': 'UA-137247681-9',
    'novi-sad.pks.rs': 'UA-137247681-10',
    'pancevo.pks.rs': 'UA-137247681-11',
    'pozarevac.pks.rs': 'UA-137247681-12',
    'sombor.pks.rs': 'UA-137247681-13',
    'sremska-mitrovica.pks.rs': 'UA-137247681-14',
    'subotica.pks.rs': 'UA-137247681-15',
    'uzice.pks.rs': 'UA-137247681-16',
    'valjevo.pks.rs': 'UA-137247681-17',
    'zajecar.pks.rs': 'UA-137247681-18',
    'zrenjanin.pks.rs': 'UA-137247681-19'
}

export const SLUGS = {
    beograd: 'pk-beograd',
    kikinda: 'rpk-kikinda',
    kragujevac: 'rpk-kragujevac',
    kraljevo: 'rpk-kraljevo',
    krusevac: 'rpk-krusevac',
    leskovac: 'rpk-leskovac',
    nis: 'rpk-nis',
    'novi-sad': 'rpk-novi-sad',
    pancevo: 'rpk-pancevo',
    pozarevac: 'rpk-pozarevac',
    sombor: 'rpk-sombor',
    'sremska-mitrovica': 'rpk-sremska-mitrovica',
    subotica: 'rpk-subotica',
    uzice: 'rpk-uzice',
    valjevo: 'rpk-valjevo',
    zajecar: 'rpk-zajecar',
    zrenjanin: 'rpk-zrenjanin'
}

export const MODAL_MARKERS = Object.freeze({
    BOARD: 'boardModal',
    GROUPATIONS: 'groupationsModal',
    PUBLICATIONS: 'publicationsModal',
    LEGISLATIONS: 'legislationsModal',
    ABOUT_INDUSTRY: 'aboutIndustryModal',
    PROJECTS: 'projectsModal',
    PRESIDENT: 'presidentModal',
    VICE_PRESIDENTS: 'vicePresidentsModal',
    PRESIDENT_ADVISORS: 'presidentAdvisorsModal',
    GENERAL_SECRETARIES: 'generalSecretariesModal',
    SECTOR_DIRECTORS: 'sectorDirectorsModal',
    DIRECTORATE_DIRECTORS: 'directorateDirectorsModal'
})

export const RC_MODAL_MARKERS = Object.freeze({
    PARLIAMENT: 'RCParliamentModal',
    BOARDS: 'RCBoardsModal',
    SECTIONS: 'RCSectionsModal',
    RC_DIRECTOR: 'RCDirectorModal',
    DIRECTOR: 'directorModal',
    SECTORS: 'sectorsModal'
})

export const AUTHORITIES = [
    {
        ID: 1,
        SLUG: 'predsednik'
    },
    {
        ID: 2,
        SLUG: 'nadzorni-odbor'
    },
    {
        ID: 3,
        SLUG: 'upravni-odbor'
    },
    {
        ID: 4,
        SLUG: 'skupstina'
    }
]

export const SECTORS = {
    'sektor-industrije': 5,
    'sektor-usluga': 6,
    'sektor-poljoprivrede': 8
}

export const PKS_SOCIAL_LINKS = {
    FB: 'https://www.facebook.com/Privrednakomora/',
    TWITTER: 'https://twitter.com/Privrednakomora',
    INSTAGRAM: 'https://www.instagram.com/privredna_komora_srbije/?hl=en',
    YOUTUBE: 'https://www.youtube.com/user/PKSMedia?feature=watch',
    LINKEDIN: 'https://www.linkedin.com/company/serbian-chamber-of-commerce-and-industry',
    PKS_MAIL: 'https://mail.pks.rs/owa'
}

export const FAIRS_CONTACTS_SLUG = 'sajmovi-kontakt'
