import React from 'react'
import ReactGA from 'react-ga'
import axios from 'axios'
import { HOSTNAMES, GA_CODES } from '../../config/pks-constants'

import { Chamber } from './../chamber/Chamber'
import { RegionalChamber } from './../regionalChamber/RegionalChamber'
import { Loader } from '../components/Loader/Loader'

import './App.css'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRegional: false,
            isLoading: true
        }
    }

    componentDidMount() {
        const { hostname } = window.location

        ReactGA.initialize(GA_CODES[hostname])

        const isRegional = element => {
            const [chamberName] = hostname.split('.')
            return element.startsWith(chamberName)
        }

        // redirection from https://en.pks.rs/
        const isRedirectedFromEngCheckInit = window.location.href.split('?')[1]
        const isRegionalChamber = HOSTNAMES.some(isRegional)

        if (isRedirectedFromEngCheckInit == 'from-eng') {
            localStorage.setItem('isRedirectedFromEngPks', 'redirected')
        }

        const isRedirectedFromEngCheck = localStorage.getItem('isRedirectedFromEngPks')
        const isPrerender = window.navigator.userAgent.includes('Prerender')
        if (
            !isPrerender &&
            (isRedirectedFromEngCheck !== 'redirected' &&
                isRedirectedFromEngCheckInit !== 'from-eng' &&
                !isRegionalChamber)
        ) {
            const serbianLangCountries = ['BA', 'RS', 'HR', 'ME', 'MK', 'XK']

            axios({
                method: 'get',
                url: 'https://pro.ip-api.com/json/?key=2ImDKPjMsVkr996'
            }).then(function(response) {
                if (serbianLangCountries.indexOf(response.data.countryCode) === -1) {
                    window.location.href = 'https://en.pks.rs/'
                }
            })
        }

        this.setState({
            isLoading: false,
            isRegional: HOSTNAMES.some(isRegional)
        })
    }

    render() {
        if (this.state.isLoading) {
            return <Loader isLoading />
        }

        if (this.state.isRegional) {
            return <RegionalChamber />
        }

        return <Chamber />
    }
}

export default App
