import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { extractDomain } from '../../../config/functions'

import { PKS_SOCIAL_LINKS } from '../../../config/pks-constants'

import { LinkList } from './LinkList/LinkList'
import { FooterInfo } from './FooterInfo/FooterInfo'

import './Footer.css'

const domain = extractDomain(window.location.host)

const Footer = ({ t }) => {
    const [isGdprClosed, setIsGdprClosed] = useState(false)

    useEffect(() => {
        const gdprAgreed = localStorage.getItem('pksGdprNotification')
        if (gdprAgreed === 'agreed') {
            setIsGdprClosed(true)
        }
    }, [])

    const acceptGdpr = () => {
        localStorage.setItem('pksGdprNotification', 'agreed')
        setIsGdprClosed(true)
    }
    const closeGdpr = () => {
        setIsGdprClosed(true)
    }

    return (
        <>
            <footer className="footer">
                <div className="container-1280">
                    <div className="footer__section">
                        <div className="footer__section__logo">
                            <img src={t('footer.logo')} alt="logo" />
                        </div>
                        <LinkList list={t('footer.contacts')} path="/" />
                    </div>
                    <div className="footer__section">
                        <h4>
                            <a
                                href={`http://${t('chamberBelgrade.slug')}.${domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >{`${t('chamberBelgrade.namePrefix')} ${t('chamberBelgrade.name')}`}</a>
                        </h4>
                        <h4>{t('footer.title')}</h4>
                        <LinkList list={t('footer.regionalChambers')} domain={domain} />
                    </div>
                    <div className="footer__section">
                        <FooterInfo info={t('footer.info')} />
                    </div>
                    <div className="footer__section">
                        <div className="footer__icons">
                            <a
                                href={PKS_SOCIAL_LINKS.TWITTER}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/twitter@3.png" alt="twitter-logo" />
                            </a>
                            <a href={PKS_SOCIAL_LINKS.FB} rel="noopener noreferrer" target="_blank">
                                <img src="/images/fb@3.png" alt="facebook-logo" />
                            </a>
                            <a
                                href={PKS_SOCIAL_LINKS.INSTAGRAM}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/instagram@3.png" alt="instagram-logo" />
                            </a>
                            <a
                                href={PKS_SOCIAL_LINKS.YOUTUBE}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/youtube@3.png" alt="youTube-logo" />
                            </a>
                            <a
                                href={PKS_SOCIAL_LINKS.LINKEDIN}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/linkedIn@3.png" alt="linkedIn-logo" />
                            </a>
                            <a
                                href={PKS_SOCIAL_LINKS.PKS_MAIL}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src="/images/mail@3.png" alt="linkedIn-logo" />
                            </a>
                        </div>
                    </div>
                    <div className="footer__privacy-policy">
                        <Link to="/politika-koriscenja-kolacica">{t('cookiesPolicyTitle')}</Link>
                        <Link to="/zastita-podataka">{t('dataProtectionTitle')}</Link>
                    </div>
                </div>
            </footer>

            {!isGdprClosed && (
                <div className="gdpr-notification-container">
                    <p className="gdpr-notification-txt">
                        {t('gdprNotificationTxtLine1')}
                        <br />
                        {t('gdprNotificationTxtLine2')}{' '}
                        <a className="gdpr-notification-link" href="/politika-koriscenja-kolacica">
                            {t('gdprNotificationTxtLink')}
                        </a>{' '}
                        <br />
                        {t('gdprNotificationTxtLine3')}
                    </p>
                    <div onClick={acceptGdpr} className="gdpr-notification-accept-btn">
                        {' '}
                        {t('gdprNotificationBtn')}
                    </div>
                    <div onClick={closeGdpr} className="gdpr-notification-close-btn-wrapper">
                        <img
                            src="/images/close-button.png"
                            className="gdpr-notification-close-btn"
                            alt="close"
                        />
                    </div>
                </div>
            )}
        </>
    )
}

Footer.propTypes = {
    t: PropTypes.func
}

export { Footer }
export default withTranslation()(Footer)
