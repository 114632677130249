import React from 'react'
import PropTypes from 'prop-types'

import { RCSectionTitle } from '../../../RCSectionTitle/RCSectionTitle'

import './RCPageInfo.css'

const RCPageInfo = ({ title, icon, bgImage, overlay = false }) => {
    bgImage = bgImage || '/images/building.jpg'
    const className = overlay ? 'overlay' : ''
    const backgroundImage = icon ? null : { backgroundImage: `url("${bgImage}")` }

    return (
        <section className={`rc-page-info rc-page-info--${className}`} style={backgroundImage}>
            <div className="container-1280">
                <div className="rc-page-info__content">
                    <div className="rc-page-info__content__title">
                        <RCSectionTitle title={title} dark />
                    </div>
                    {icon && (
                        <div className="rc-page-info__content__image">
                            <img src={icon} alt={title} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

RCPageInfo.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    overlay: PropTypes.bool
}

export { RCPageInfo }
