import React, { useState, useEffect } from 'react'
import './OpenBalkanSporazumPage.css'

const OpenBalkanSporazumPage = props => {
    const [sectionData, setSectionData] = useState(null)

    useEffect(() => {
        let lang = JSON.parse(localStorage.getItem('language'))

        fetch(`https://api.pks.rs/public-api/open-balkan/sections/${props.match.params.slug}`, {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(data => setSectionData(data.body.entry))
    }, [])

    return (
        <>
            {sectionData && (
                <main className="open-balkan-topmargin">
                    <div className="section-container container-1280">
                        <h1 className="top-section-title__text dark top-section-title__text--underlined">
                            {sectionData.title}
                        </h1>
                        <div
                            dangerouslySetInnerHTML={{ __html: sectionData.text }}
                            className="open-balkan-inner-html-content"
                        />
                    </div>
                </main>
            )}
        </>
    )
}

export default OpenBalkanSporazumPage
