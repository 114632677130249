class ImageItem {
    constructor({ title, description, source, image }) {
        this.title = title
        this.description = description
        this.image = image
        this.source = source
    }

    get url() {
        return this.image.original
    }

    get originalSize() {
        return this.image.original
    }

    get thumbnail() {
        return this.image['thumbnail-120x90']
    }

    get verticalLargeImage() {
        return this.image['634px-x-640px']
    }

    get horizontalLargeImage() {
        return this.image['634px-x-312px']
    }

    get headlineImage() {
        return this.image['830px-x-482px']
    }

    get articleSliderImage() {
        return this.image['800px-x-482px']
    }

    get mediumThumbnail() {
        return this.image['98px-x-98px']
    }

    get eventHeadline() {
        return this.image['682px-x-200px']
    }

    get largeThumbnail() {
        return this.image['230px-x-230px']
    }
}

export { ImageItem }
