import _ from 'lodash'

import { IdNameEntity } from './IdNameEntity'

class EventCategory extends IdNameEntity {
    constructor({ id, name, description }) {
        super({ id, name })

        this.description = description
    }

    get hasHtmlDescription() {
        return !_.isEmpty(this.description)
    }

    get truncatedDescription() {
        return _.truncate(this.description, {
            length: 400,
            separator: /\./,
            omission: '.'
        })
    }

    // FIXME: FETCH FROM BACKEND

    get htmlTruncatedDescription() {
        return { __html: this.truncatedDescription }
    }

    get htmlDescription() {
        return this.hasHtmlDescription && { __html: this.description }
    }
}

export { EventCategory }
