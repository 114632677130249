import React from 'react'
import PropTypes from 'prop-types'

import './TopSectionTitle.css'
import { Hide } from '../Hide'

const TopSectionTitle = ({ title, underlined = false, dark = false, centered = false }) => {
    const className = underlined && title ? 'top-section-title__text--underlined' : ''
    const position = centered ? 'top-section-title--centered' : ''
    const color = dark ? 'dark' : ''

    return (
        <Hide data={title}>
            <div className={`top-section-title ${position}`}>
                <h3 className={`top-section-title__text ${color} ${className}`}>{title}</h3>
            </div>
        </Hide>
    )
}

TopSectionTitle.propTypes = {
    title: PropTypes.string,
    underlined: PropTypes.bool,
    dark: PropTypes.bool,
    centered: PropTypes.bool
}

export { TopSectionTitle }
