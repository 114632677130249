import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import i18n from './../../../config/i18n'

import { languageService } from './../../../services/languageService'
import { regionalChambersCommunicator } from './../../../communicators/regionalChambersCommunicator'

import { RCHamburgerNavigationList } from './RCHamburgerNavigationList/RCHamburgerNavigationList'

import './RCHeader.css'

class RCHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chamber: null,
            isOpen: false,
            langLat: languageService.getActiveLanguage('language') ? true : false
        }
    }

    componentDidMount() {
        this.loadChamber()
    }

    componentDidUpdate(prevProps) {
        if (this.props.slug !== prevProps.slug) {
            this.loadChamber()
        }
    }

    async loadChamber() {
        try {
            const { slug } = this.props
            const chamber = await regionalChambersCommunicator.fetchChamber(slug)

            this.setState({ chamber })
        } catch (error) {
            console.error(error)
        }
    }

    onLogoClick = e => {
        this.state.isOpen && this.toggleDropdown()
    }

    toggleScrollLock() {
        document.querySelector('html').classList.toggle('page-lock-scroll-tablet')
    }

    toggleDropdown = () => {
        this.setState(prevState => ({ ...prevState, isOpen: !prevState.isOpen }))
        this.toggleScrollLock()
    }

    removeScrollLock() {
        document.querySelector('html').classList.remove('page-lock-scroll-tablet')
    }

    onCloseDropdown = () => {
        this.setState({ isOpen: false })
        this.removeScrollLock()
    }

    onLanguageSelectorClick = e => {
        if (e.target.classList.value === 'active-language') {
            this.setState({ isOpen: false })
            return
        }
        const active = this.state.langLat ? '' : 'sr_lat'
        languageService.setActiveLanguage('language', active)
        this.setState(prevState => ({ ...prevState, langLat: !prevState.langLat }))
        window.location.reload()
    }

    render() {
        const { chamber, langLat, isOpen } = this.state
        const menuNavigation = isOpen ? 'open' : 'close'

        const chamberLogo = langLat
            ? '/images/logo-white_lat@3x.png'
            : '/images/logo-white_cir@3x.png'

        const domain = window.location.host
            .split('.')
            .slice(1)
            .join('.')

        return (
            chamber && (
                <header className="RC-header">
                    <div className="container-1280">
                        <div className="RC-header__logo" onClick={this.onLogoClick}>
                            <a href={`http://${domain}`}>
                                <img
                                    src={chamberLogo}
                                    alt="logo"
                                    className="RC-header__logo--large"
                                />
                                <img
                                    src="/images/RC-logo-mobile.png"
                                    alt="RC-logo-mobile"
                                    className="RC-header__logo--mobile"
                                />
                            </a>
                        </div>
                        <div className="RC-header__content">
                            <NavLink className="RC-header__content__chamber-name" to={`/`}>
                                <h1>{chamber.name}</h1>
                                <h3>{chamber.fullName}</h3>
                            </NavLink>
                            <ul className="RC-header__content__navigation">
                                {chamber.headerPages.map((navItem, i) => (
                                    <li key={i}>
                                        <NavLink
                                            to={`/strana/${navItem.slug}`}
                                            activeClassName="is-active-link"
                                        >
                                            {navItem.title}
                                        </NavLink>
                                    </li>
                                ))}
                                <li>
                                    <NavLink to={`/kontakt`} activeClassName="is-active-link">
                                        {i18n.t('regionalChamber.contact.title')}
                                    </NavLink>
                                </li>
                                <li>
                                    <ul className="RC-header__content__language">
                                        <li
                                            onClick={this.onLanguageSelectorClick}
                                            className={langLat ? '' : 'active-language'}
                                        >
                                            ћир
                                        </li>
                                        <li
                                            onClick={this.onLanguageSelectorClick}
                                            className={langLat ? 'active-language' : ''}
                                        >
                                            lat
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="RC-header__image">
                            <img src={chamber.logo} alt="" />
                        </div>
                        <div
                            className={`RC-header__menu RC-header__menu--${menuNavigation}`}
                            onClick={this.toggleDropdown}
                        >
                            <img
                                src="/images/RC_hamburger.png"
                                alt="RC-menu"
                                className="RC-header__menu__hamburger"
                            />
                            <img
                                src="/images/RC_close-menu.png"
                                alt="RC-menu"
                                className="RC-header__menu__close-menu"
                            />
                        </div>
                    </div>
                    {this.state.isOpen && (
                        <RCHamburgerNavigationList
                            navigation={chamber.headerPages}
                            onLanguageSelectorClick={this.onLanguageSelectorClick}
                            onCloseDropdown={this.onCloseDropdown}
                            langLat={langLat}
                            title={chamber.fullName}
                        />
                    )}
                </header>
            )
        )
    }
}

RCHeader.propTypes = {
    t: PropTypes.func
}

export { RCHeader }
