import React from 'react'
import { NavLink } from 'react-router-dom'
import { navigationProps } from '../propTypes'

import './HeaderNav.css'

const HeaderNav = ({ navigation }) => (
    <ul className="header-nav__nav">
        {navigation.map((navItem, i) => (
            <li key={i}>
                <NavLink to={navItem.link} activeClassName="is-active">
                    {navItem.title}
                </NavLink>
            </li>
        ))}
    </ul>
)

HeaderNav.propTypes = {
    navigation: navigationProps
}

export { HeaderNav }
