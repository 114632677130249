import dayjs from 'dayjs'
import _ from 'lodash'
import { ImageItem } from './ImageItem'
import { NewsCategory } from './NewsCategory'

class News {
    constructor({
        id,
        approved,
        categories,
        sector,
        association,
        regional_chamber,
        title,
        title_slug,
        content_short,
        antrfile,
        content,
        expiration_date,
        created_at,
        updated_at,
        publish_date,
        youtube_link,
        videonet_link,
        gallery_items,
        headline_image
    }) {
        this.id = id
        this.approved = approved
        this.categoriesList = categories
        this.sector = sector
        this.association = association
        this.regionalChamber = regional_chamber
        this.title = title
        this.articleSlug = title_slug
        this.contentShort = content_short
        this.antrfile = antrfile
        this.content = content
        this.expirationDate = expiration_date
        this.createdDate = created_at
        this.publishDate = publish_date
        this.updatedDate = updated_at
        this.youtubeLink = youtube_link
        this.videonetLink = videonet_link
        this.galleryItems = gallery_items
            ? gallery_items.map(imageItem => imageItem && new ImageItem(imageItem))
            : []
        this.headlineImage = headline_image && new ImageItem(headline_image)
    }

    get htmlIntro() {
        return { __html: this.antrfile }
    }

    get newsDescription() {
        const temporalDIV = document.createElement('div')
        temporalDIV.innerHTML = this.contentShort

        return temporalDIV.textContent || ''
    }

    get htmlContentShort() {
        return { __html: this.contentShort }
    }

    get htmlContent() {
        return { __html: this.content }
    }

    get originalHeadlineImage() {
        return this.headlineImage && this.headlineImage.originalSize
    }

    get originalImage() {
        return this.originalHeadlineImage || '/images/news-placeholder-img.png'
    }

    get verticalLargeImage() {
        return this.headlineImage ? this.headlineImage.verticalLargeImage : this.originalImage
    }

    get horizontalLargeImage() {
        return this.headlineImage ? this.headlineImage.horizontalLargeImage : this.originalImage
    }

    get articleHeadlineImage() {
        return this.headlineImage ? this.headlineImage.headlineImage : this.originalImage
    }

    get relatedNewsImage() {
        return this.headlineImage ? this.headlineImage.mediumThumbnail : this.originalImage
    }

    get allNewsImage() {
        return this.headlineImage ? this.headlineImage.largeThumbnail : this.originalImage
    }

    get imageSource() {
        return this.headlineImage ? this.headlineImage.source : ''
    }

    get slug() {
        return this.articleSlug || this.fallbackSlug
    }

    get fallbackSlug() {
        const titleSlug = this.title
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
        const slug = `${titleSlug}--${this.id}`
        return slug
    }

    get dateToDisplay() {
        return this.publishDate || this.createdDate
    }

    get displayDate() {
        return dayjs(this.dateToDisplay)
            .format('DD. MMMM YYYY.')
            .toLowerCase()
    }

    get truncatedHtmlIntro() {
        let descriptionText = ''
        switch (true) {
            case !_.isEmpty(this.contentShort):
                descriptionText = this.contentShort
                break
            case !_.isEmpty(this.antrfile):
                descriptionText = this.antrfile
                break
            default:
                descriptionText = this.content
                break
        }

        return {
            __html: _.truncate(descriptionText, { length: 150, separator: ' ' })
        }
    }

    get truncatedTitle() {
        return _.truncate(this.title, {
            length: 55,
            separator: /,? +/
        })
    }
    get truncatedTitleMobile() {
        return _.truncate(this.title, {
            length: 90,
            separator: /,? +/
        })
    }

    get hasCategories() {
        return !_.isEmpty(this.categoriesList)
    }

    get categories() {
        return this.hasCategories ? this.categoriesList.map(item => new NewsCategory(item)) : []
    }
}

export { News }
