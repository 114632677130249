import React from 'react'
import PropTypes from 'prop-types'

import './SectionTitle.css'

const SectionTitle = ({ title, underlineClass, dark }) => {
    const className = underlineClass && title ? 'title-underline' : ''

    if (dark) {
        return <h3 className={`section-title dark ${className}`}>{title}</h3>
    }

    return <h3 className={`section-title ${className}`}>{title}</h3>
}

SectionTitle.propTypes = {
    title: PropTypes.string,
    underlineClass: PropTypes.bool,
    dark: PropTypes.bool
}

SectionTitle.defaultProps = {
    underlineClass: false,
    dark: false
}

export { SectionTitle }
