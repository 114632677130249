import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import i18n from './../../../config/i18n'

import _ from 'lodash'

import RCLinkList from './RCLinkList/RCLinkList'
import RCFooterInfo from './RCFooterInfo/RCFooterInfo'

import './RCFooter.css'
import { RCSectionBlock } from '../RCSectionBlock/RCSectionBlock'
import { regionalChambersCommunicator } from '../../../communicators/regionalChambersCommunicator'

class RCFooter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chamber: null,
            isGdprClosed: false
        }
    }

    componentDidMount() {
        this.loadChamber()
        this.checkIsGdprAgreed()
    }

    componentDidUpdate(prevProps) {
        if (this.props.slug !== prevProps.slug) {
            this.loadChamber()
        }
    }

    async loadChamber() {
        try {
            const { slug } = this.props
            const chamber = await regionalChambersCommunicator.fetchChamber(slug)

            this.setState({ chamber })
        } catch (error) {
            console.error(error)
        }
    }

    checkIsGdprAgreed = () => {
        const gdprAgreed = localStorage.getItem('pksGdprNotification')
        if (gdprAgreed === 'agreed') {
            this.setState({
                isGdprClosed: true
            })
        }
    }

    acceptGdpr = () => {
        localStorage.setItem('pksGdprNotification', 'agreed')
        this.setState({
            isGdprClosed: true
        })
    }
    closeGdpr = () => {
        this.setState({
            isGdprClosed: true
        })
    }

    render() {
        const { chamber } = this.state
        const { t } = this.props

        if (_.isEmpty(chamber)) {
            return null
        }

        const domain = window.location.host
            .split('.')
            .slice(1)
            .join('.')

        return (
            <>
                <footer className="RC-footer">
                    <RCSectionBlock withContainer>
                        <div className="RC-footer__section">
                            <a href={`http://${domain}`} className="RC-footer__section__logo">
                                <img src={i18n.t('footer.logo')} alt="logo" />
                            </a>
                        </div>
                        <div className="RC-footer__section">
                            <RCLinkList list={i18n.t('footer.regionalChambers')} domain={domain} />
                        </div>
                        <div className="RC-footer__section">
                            <RCFooterInfo
                                name={chamber.fullName}
                                contactDetails={chamber.htmlContact}
                                // pib={chamber.pib}
                                // identificationNumber={chamber.identificationNumber}
                            />
                        </div>
                        <div className="RC-footer__section">
                            <div className="RC-footer__icons">
                                <a
                                    href={chamber.twitterLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img src="/images/twitter@3.png" alt="twitter-logo" />
                                </a>
                                <a href={chamber.fbLink} rel="noopener noreferrer" target="_blank">
                                    <img src="/images/fb@3.png" alt="facebook-logo" />
                                </a>
                                <a
                                    href={chamber.instagramLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img src="/images/instagram@3.png" alt="instagram-logo" />
                                </a>
                                <a
                                    href={chamber.youtubeLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img src="/images/youtube@3.png" alt="youTube-logo" />
                                </a>
                                <a
                                    href={chamber.linkedinLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img src="/images/linkedIn@3.png" alt="linkedIn-logo" />
                                </a>
                            </div>
                        </div>

                        <div className="RC-footer__privacy-policy">
                            <Link to="/zastita-podataka">{t('dataProtectionTitle')}</Link>
                        </div>
                    </RCSectionBlock>
                </footer>

                {!this.state.isGdprClosed && (
                    <div className="gdpr-notification-container">
                        <p className="gdpr-notification-txt">
                            {t('gdprNotificationTxtLine1')}
                            <br />
                            {t('gdprNotificationTxtLine2')}{' '}
                            <a
                                className="gdpr-notification-link"
                                href="/politika-koriscenja-kolacica"
                            >
                                {t('gdprNotificationTxtLink')}
                            </a>{' '}
                            <br />
                            {t('gdprNotificationTxtLine3')}
                        </p>
                        <div onClick={this.acceptGdpr} className="gdpr-notification-accept-btn">
                            {' '}
                            {t('gdprNotificationBtn')}
                        </div>
                        <div
                            onClick={this.closeGdpr}
                            className="gdpr-notification-close-btn-wrapper"
                        >
                            <img
                                src="/images/close-button.png"
                                className="gdpr-notification-close-btn"
                                alt="close"
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }
}

RCFooter.propTypes = {
    t: PropTypes.func
}

export default withTranslation()(RCFooter)
