import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { newsCommunicator } from '../../../communicators/newsCommunicator'

import { SectionTitle } from '../SectionTitle/SectionTitle'
import { AllNewsItem } from '../AllNewsItem/AllNewsItem'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { Pagination } from '../Pagination/Pagination'

import './AllNews.css'

class AllNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            unmarkedNews: [],
            pagesList: [],
            lastPage: 1
        }

        this.updateAllNews = this.updateAllNews.bind(this)
    }

    getPages(n) {
        const array = []
        for (let i = 1; i <= n; i++) {
            array.push(i)
        }

        return array
    }

    async loadAllNews() {
        try {
            const { id, queryParam, excludeMarkedNews, withApproval } = this.props

            const response = await newsCommunicator.fetchAllNews(
                queryParam,
                id,
                1,
                excludeMarkedNews,
                withApproval
            )
            this.setState({
                unmarkedNews: response.news,
                lastPage: response.pagination.totalPages,
                pagesList: this.getPages(response.pagination.totalPages)
            })
        } catch (error) {
            console.error(error.message)
        }
    }

    async updateAllNews(currentPage) {
        try {
            const { id, queryParam, withApproval } = this.props

            const response = await newsCommunicator.fetchAllNews(
                queryParam,
                id,
                currentPage,
                withApproval
            )

            this.setState({ unmarkedNews: response.news })
        } catch (error) {
            console.error(error.message)
        }
    }

    componentDidMount() {
        const { id, queryParam } = this.props

        this.loadAllNews(queryParam, id)
    }

    render() {
        const { title } = this.props
        const { unmarkedNews, pagesList, lastPage } = this.state

        if (_.isEmpty(unmarkedNews)) {
            return null
        }

        return (
            <section className="all-news">
                <div className="section-title-centered">
                    <SectionTitle title={title} underlineClass dark />
                </div>
                <ErrorBoundary>
                    <div className="all-news__content container-1280">
                        {unmarkedNews.map((article, i) => (
                            <AllNewsItem newsItem={article} key={article.id} />
                        ))}
                    </div>
                </ErrorBoundary>
                {pagesList.length > 1 && (
                    <Pagination
                        lastPage={lastPage}
                        pagesList={pagesList}
                        updateContent={this.updateAllNews}
                    />
                )}{' '}
            </section>
        )
    }
}

AllNews.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    queryParam: PropTypes.string.isRequired,
    excludeMarkedNews: PropTypes.bool,
    withApproval: PropTypes.bool
}

export { AllNews }
