import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorSection from '../ErrorSection/ErrorSection'

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        error: null,
        errorInfo: null
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error, errorInfo: info })
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) {
            return <ErrorSection />
        }
        return children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
}

export { ErrorBoundary }
