import PropTypes from 'prop-types'

const navigationProps = PropTypes.arrayOf(
    PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })
).isRequired

export { navigationProps }
