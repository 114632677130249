import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { PageInfo } from '../../components/PageInfo/PageInfo'
import { TopNews } from '../TopNews/TopNews'
import { AllNews } from '../AllNews/AllNews'
import EventsSection from '../EventsSection/EventsSection'

import './GeneralNews.css'

const GeneralNews = ({
    t,
    id = null,
    queryParam = '',
    title,
    subtitle,
    excludeMarkedNews,
    withApproval = false
}) => {
    return (
        <div className="general-news">
            <PageInfo
                breadcrumbs={t('generalNews.breadcrumbs')}
                title={title}
                bgImage="/images/vest.jpg"
                overlay
                subtitle={subtitle}
            />
            <TopNews
                id={id}
                queryParam={queryParam}
                title={t('generalNews.topNews.title')}
                withApproval={withApproval}
            />
            <AllNews
                id={id}
                queryParam={queryParam}
                title={t('generalNews.allNews.title')}
                excludeMarkedNews
                withApproval={withApproval}
            />
            <div className="general-news__events-section">
                <EventsSection
                    id={id}
                    queryParam={queryParam}
                    n={3}
                    title={t('generalNews.eventsSection.title')}
                    withApproval={withApproval}
                />
            </div>
        </div>
    )
}

GeneralNews.propTypes = {
    t: PropTypes.func,
    id: PropTypes.number,
    queryParam: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    excludeMarkedNews: PropTypes.bool,
    withApproval: PropTypes.bool
}

export { GeneralNews }
export default withTranslation()(GeneralNews)
