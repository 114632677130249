import React from 'react'

import i18n from '../../../config/i18n'

import { withRouter } from 'react-router-dom'

import { Helmet } from 'react-helmet'

const HelmetComponent = ({
    title = i18n.t('headTitle'),
    description = i18n.t('metaDescription'),
    ogTitle = i18n.t('headTitle'),
    ogType = 'website',
    twitterTitle = i18n.t('headTitle'),
    image = '/images/logo2x.jpg',
    keywords = i18n.t('metaKeywords')
}) => (
    <Helmet key={window.location.href}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keyword" content={keywords} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        {/* <!-- Facebook Pixel Code --> */}
        <script>
            {`
                !function (f, b, e, v, n, t, s) {
                    if (f.fbq) return; n = f.fbq = function () {
                        n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
                    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                    n.queue = []; t = b.createElement(e); t.async = !0;
                    t.src = v; s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s)
                }(window, document, 'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '688061241606358');
                fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=688061241606358&ev=PageView&noscript=1"
            />`}</noscript>
        {/* <!-- End Facebook Pixel Code --> */}

        <script type="text/javascript">
            {`
                _linkedin_partner_id = '1269186'
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
                window._linkedin_data_partner_ids.push(_linkedin_partner_id)`}
        </script>
        <script type="text/javascript">
            {`(function () {
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript"; b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);
                })()`}
        </script>
        <noscript>{`
                <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1269186&fmt=gif" />
            `}</noscript>

        {/* <!-- New Meta Pixel Code --> */}
        <script>
            {` !(function(f, b, e, v, n, t, s) {
                if (f.fbq) return
                n = f.fbq = function() {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                if (!f._fbq) f._fbq = n
                n.push = n
                n.loaded = !0
                n.version = '2.0'
                n.queue = []
                t = b.createElement(e)
                t.async = !0
                t.src = v
                s = b.getElementsByTagName(e)[0]
                s.parentNode.insertBefore(t, s)
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
            fbq('init', '928029641704873')
            fbq('track', 'PageView')`}
        </script>
        <noscript>{`<img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=928029641704873&ev=PageView&noscript=1"
        />`}</noscript>
        {/* <!-- End New Meta Pixel Code --> */}

        {/* <!-- New LinkedIn Code --> */}
        <script type="text/javascript">
            {` _linkedin_partner_id = '347473'
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
            window._linkedin_data_partner_ids.push(_linkedin_partner_id)`}
        </script>
        <script type="text/javascript">
            {`       ;(function(l) {
                if (!l) {
                    window.lintrk = function(a, b) {
                        window.lintrk.q.push([a, b])
                    }
                    window.lintrk.q = []
                }
                var s = document.getElementsByTagName('script')[0]
                var b = document.createElement('script')
                b.type = 'text/javascript'
                b.async = true
                b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
                s.parentNode.insertBefore(b, s)
            })(window.lintrk)`}
        </script>
        <noscript>
            {` <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=347473&fmt=gif"
            />`}
        </noscript>
        {/* <!-- End New LinkedIn Code --> */}

        {/* START UP pixels */}
        {window.location.href === 'https://pks.rs/komorske-usluge/start-up' && (
            <>
                <script>
                    {` !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '353870238554355');
                        fbq('track', 'PageView');`}
                </script>
                <noscript>
                    <img
                        height="1"
                        width="1"
                        alt="startup"
                        style={{ display: 'none' }}
                        src="https://www.facebook.com/tr?id=353870238554355&ev=PageView&noscript=1"
                    />
                </noscript>
                <script type="text/javascript">
                    {`   _linkedin_partner_id = "347473";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
                </script>
                <script type="text/javascript">
                    {`   (function(){var s = document.getElementsByTagName("script")[0];
                        var b = document.createElement("script");
                        b.type = "text/javascript";b.async = true;
                        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                        s.parentNode.insertBefore(b, s);})();`}
                </script>
                <noscript>
                    <img
                        height="1"
                        width="1"
                        alt="startup"
                        style={{ display: 'none' }}
                        src="https://px.ads.linkedin.com/collect/?pid=347473&fmt=gif"
                    />
                </noscript>
            </>
        )}
    </Helmet>
)

export default withRouter(HelmetComponent)
