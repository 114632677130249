import _ from 'lodash'

class Participator {
    constructor({ full_name, phone, email }) {
        this.fullName = full_name
        this.phone = phone
        this.email = email
    }

    get phoneNumbers() {
        return this.phone && this.phone.split('; ')
    }

    get hasPhoneNumbers() {
        return !_.isEmpty(this.phoneNumbers)
    }
}

export { Participator }
