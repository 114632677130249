import axios from 'axios'
import { http } from '../services/httpService'
import { API_BASE_URL, ERROR_DATA_UNAVAILABLE, ERROR_CONNECTION_ISSUE } from '../config/constants'
import { languageService } from '../services/languageService'

class PksApiService {
    async fetchData(requestEndpoint, params) {
        return this.fetchDataWithMeta(requestEndpoint, params).then(body => {
            if (body && body.entry) {
                return body.entry
            } else if (body && body.entries) {
                return body.entries
            } else {
                return body
            }
        })
    }

    async fetchDataWithMeta(requestEndpoint, params) {
        const requestOptions = {
            baseURL: API_BASE_URL,
            url: requestEndpoint,
            params,
            headers: {
                language: languageService.activeLanguage,
                Accept: 'application/json'
            }
        }

        const response = await axios(requestOptions)

        const { data } = response
        const { body } = data

        if (body) {
            return body
        }

        return Promise.reject({
            error: ERROR_DATA_UNAVAILABLE
        })
    }

    async fetchInfo(requestEndpoint, queryParams = {}) {
        return this.fetchDataWithMeta(requestEndpoint, queryParams).then(body => {
            return body.pagination
        })
    }

    fetch(endPoint, id) {
        const url = `${API_BASE_URL}${endPoint}/${id}`
        const options = {
            headers: {
                language: languageService.activeLanguage,
                Accept: 'application/json'
            }
        }

        return http
            .get(url, options)
            .then(({ data }) =>
                data.body.entry
                    ? data.body.entry
                    : Promise.reject({
                          error: ERROR_DATA_UNAVAILABLE
                      })
            )
            .catch(err =>
                Promise.reject({
                    error: ERROR_CONNECTION_ISSUE
                })
            )
    }

    fetchEntry(endPoint, pageSize = 20, page = 1, queryParams = {}) {
        const url = `${API_BASE_URL}${endPoint}`
        const options = {
            headers: {
                language: languageService.activeLanguage,
                Accept: 'application/json'
            }
        }

        const params = {
            offset: pageSize,
            page,
            ...queryParams
        }

        return http
            .get(url, options, params)
            .then(({ data }) =>
                data.body.entry
                    ? data.body.entry
                    : Promise.reject({
                          error: ERROR_DATA_UNAVAILABLE
                      })
            )
            .catch(err =>
                Promise.reject({
                    error: ERROR_CONNECTION_ISSUE
                })
            )
    }

    fetchPage(endPoint, pageSize = 20, page = 1, queryParams = {}) {
        const url = `${API_BASE_URL}${endPoint}`
        const options = {
            headers: {
                language: languageService.activeLanguage,
                Accept: 'application/json'
            }
        }

        const params = {
            offset: pageSize,
            page,
            ...queryParams
        }

        return http
            .get(url, options, params)
            .then(({ data }) =>
                data.body.entries
                    ? data.body.entries
                    : Promise.reject({
                          error: ERROR_DATA_UNAVAILABLE
                      })
            )
            .catch(err =>
                Promise.reject({
                    error: ERROR_CONNECTION_ISSUE
                })
            )
    }
}

export const pksApiService = new PksApiService()
