import React from 'react'
import PropTypes from 'prop-types'

import HelmetComponent from '../HelmetComponent/HelmetComponent'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { Loader } from '../Loader/Loader'

import './Page.css'

const Page = ({
    children,
    title,
    description,
    ogTitle,
    ogType,
    image,
    twitterTitle,
    keywords,
    isLoading = false,
    isGrayBg = true
}) => {
    const className = isGrayBg ? 'gray-bg' : ''
    return (
        <>
            <HelmetComponent
                title={title}
                description={description}
                ogTitle={ogTitle}
                ogType={ogType}
                image={image}
                twitterTitle={twitterTitle}
                keywords={keywords}
            />
            <Loader isLoading={isLoading}>
                <ErrorBoundary>
                    <main className={`page ${className}`}>{children}</main>
                </ErrorBoundary>
            </Loader>
        </>
    )
}

Page.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    ogType: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.string,
    isLoading: PropTypes.bool,
    isGrayBg: PropTypes.bool
}

export { Page }
