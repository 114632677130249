import React from 'react'
import { additionalInfoProps } from '../propTypes'
import { Link } from 'react-router-dom'

import './AdditionalInfo.css'

const AdditionalInfo = ({ additionalInfo }) => {
    const { associationName, sectorId } = additionalInfo

    const setSectorInfo = () => {
        switch (sectorId) {
            case 5:
                return 'Sektor za industriju'
            case 6:
                return 'Sektor za usluge'
            case 8:
                return 'Sektor za poljoprivredu'
            default:
                return ''
        }
    }

    return (
        <div className="additional-info">
            <Link to="/association/1">
                <span>{associationName}</span>
            </Link>
            <Link to={`/sector/${sectorId}`}>
                <span>{setSectorInfo()}</span>
            </Link>
        </div>
    )
}

AdditionalInfo.propTypes = {
    additionalInfo: additionalInfoProps
}

export { AdditionalInfo }
