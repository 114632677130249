import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { navigationProps } from '../propTypes'

import './HamburgerNavigationList.css'

const HamburgerNavigationList = ({
    navigation,
    onLanguageSelectorClick,
    onCloseDropdown,
    langLat
}) => (
    <div className="hamburger-navigation-list">
        <ul className="hamburger-navigation-list__options">
            <li onClick={onLanguageSelectorClick} className={langLat ? '' : 'active-language'}>
                ћир
            </li>
            <li onClick={onLanguageSelectorClick} className={langLat ? 'active-language' : ''}>
                lat
            </li>
            <a href="https://en.pks.rs/" target="_blank" rel="noopener noreferrer">
                <li>eng</li>
            </a>
        </ul>
        <ul className="hamburger-navigation-list__nav">
            {navigation.map((navItem, i) => (
                <li key={i}>
                    <NavLink
                        to={navItem.link}
                        activeClassName="is-active"
                        onClick={onCloseDropdown}
                    >
                        {navItem.title}
                    </NavLink>
                </li>
            ))}
        </ul>
        <h6 className="hamburger-navigation-list__call-center">
            Call center <span>0800 808 809</span>
        </h6>
    </div>
)

HamburgerNavigationList.propTypes = {
    navigation: navigationProps,
    onLanguageSelectorClick: PropTypes.func.isRequired,
    onCloseDropdown: PropTypes.func.isRequired,
    langLat: PropTypes.bool.isRequired
}

export { HamburgerNavigationList }
