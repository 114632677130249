import _ from 'lodash'

import { MEDIA_BASE_URL } from '../config/constants'

export class DocumentFile {
    constructor({ title, file_path, file_name, description }) {
        this.title = title
        this.name = file_name
        this.path = file_path
        this.description = description
    }

    get displayText() {
        return this.title || this.name
    }

    get fileExtension() {
        const defaultFileExtension = 'pdf'
        if (this.name) {
            return this.name.slice(this.name.lastIndexOf('.') + 1, this.name.length)
        }
        return defaultFileExtension
    }

    get fileIcons() {
        return {
            docx: '/images/word.png',
            pdf: '/images/pdf.png',
            doc: '/images/doc.png',
            xls: '/images/doc.png',
            docx_white: '/images/docx_white.png',
            pdf_white: '/images/pdf_white.png',
            doc_white: '/images/doc_white.png',
            xls_white: '/images/doc_white.png'
        }
    }

    getIcon(dark = true) {
        const iconMode = dark ? `${this.fileExtension}_white` : this.fileExtension
        return this.fileIcons[iconMode]
    }

    get documentUrl() {
        return `${MEDIA_BASE_URL}${this.name}`
    }

    get hasHtmlDescription() {
        return !_.isEmpty(this.description)
    }

    get htmlDescription() {
        return { __html: this.description }
    }
}
