import React, { useState, useEffect } from 'react'
import './OpenBalkanUcesnici.css'

const OpenBalkanUcesniciPage = props => {
    const [ucesnici, setUcesnici] = useState(null)

    useEffect(() => {
        let lang = JSON.parse(localStorage.getItem('language'))

        fetch(`https://api.pks.rs/public-api/open-balkan/ucesnici`, {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(data => setUcesnici(data.body.entries))
    }, [])

    const mapUcesnici = () => {
        return ucesnici.map((ucesnik, index) => {
            return (
                <div
                    key={`open-balkan-ucesnik-${index}`}
                    className="open-balkan-single-ucesnik-wrapper"
                >
                    <h2 className="open-balkan-ucesnik-name">{ucesnik.name}</h2>
                    <div className="open-balkan-ucesnik-horizontal-line" />
                    <p className="open-balkan-ucesnik-txt">{ucesnik.population}</p>
                    <p className="open-balkan-ucesnik-txt">{ucesnik.gdp}</p>
                    <p className="open-balkan-ucesnik-txt">{ucesnik.gdp_pc}</p>
                </div>
            )
        })
    }
    return (
        <main className="open-balkan-topmargin open-balkan-ucesnici-container open-balkan-ucesnici-flex-container">
            {ucesnici && <>{mapUcesnici()}</>}
        </main>
    )
}

export default OpenBalkanUcesniciPage
