import { DocumentFile } from './DocumentFile'

export class ForeignRepresentative {
    constructor({ id, name, address, contact_person, description, documents, email, phone }) {
        this.id = id
        this.name = name
        this.address = address
        this.contactPerson = contact_person
        this.description = description
        this.documents = documents.length > 0 ? documents.map(doc => new DocumentFile(doc)) : []
        this.email = email
        this.phone = phone
        this.active = false
    }
}
