import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { withAnalytics } from './App/withAnalytics'

import { SLUGS } from '../config/pks-constants'
import { Loader } from './components/Loader/Loader'
import NotFoundPage from './components/NotFoundPage/NotFoundPage'
import RCNotFoundPage from './regionalChamber/pages/RCNotFoundPage/RCNotFoundPage'
import RCCategoryNews from './regionalChamber/pages/RCCategoryNews/RCCategoryNews'
import RCDataProtection from './regionalChamber/pages/RCDataProtection/RCDataProtection'
import DubaiExpo2020 from './components/DubaiExpo2020/DubaiExpo2020'
import OpenBalkan from './components/OpenBalkan/OpenBalkan'
import OpenBalkanSporazumPage from './components/OpenBalkan/SporazumPage/OpenBalkanSporazumPage'
import OpenBalkanUcesniciPage from './components/OpenBalkan/UcesniciPage/OpenBalkanUcesniciPage'

import SingleActivityPage from './components/DubaiExpo2020/SingleActivityPage/SingleActivityPage'
import SingleActivityPageOP from './components/OpenBalkan/SingleActivityPage/SingleActivityPage'

// import GoogleMapPage from './chamber/GoogleMapPage/GoogleMapPage'

const GoogleMapPage = lazy(() => import('./chamber/GoogleMapPage/GoogleMapPage'))
const AboutUs = lazy(() => import('./chamber/About/AboutUs/AboutUs'))
const President = lazy(() => import('./chamber/About/President/President'))
const Assembly = lazy(() => import('./chamber/About/Assembly/Assembly'))
const SteeringCommittee = lazy(() => import('./chamber/About/SteeringCommittee/SteeringCommittee'))
const SupervisoryCommittee = lazy(() =>
    import('./chamber/About/SupervisoryCommittee/SupervisoryCommittee')
)
const AllEvents = lazy(() => import('./chamber/Events/AllEvents/AllEvents'))
const AllEventsByCategory = lazy(() =>
    import('./chamber/Events/AllEventsByCategory/AllEventsByCategory')
)
const EventDetails = lazy(() => import('./chamber/Events/EventDetails/EventDetails'))
const Association = lazy(() => import('./chamber/Association/Association'))
const Sector = lazy(() => import('./chamber/Sector/Sector'))
const AssociationNews = lazy(() => import('./chamber/News/AssociationNews'))
const News = lazy(() => import('./chamber/News/News'))
const CategoryNews = lazy(() => import('./chamber/News/CategoryNews'))
const SectorNews = lazy(() => import('./chamber/News/SectorNews'))
const SingleArticle = lazy(() => import('./chamber/News/SingleArticle/SingleArticle'))
const ChamberServices = lazy(() => import('./chamber/ChamberServices/ChamberServices'))
const ServiceDetails = lazy(() => import('./chamber/ChamberServices/ServiceDetails/ServiceDetails'))
const AllChambers = lazy(() => import('./chamber/AllChambers/AllChambers'))
const ContactPage = lazy(() => import('./chamber/ContactPage/ContactPage'))
const DataProtectionPage = lazy(() => import('./chamber/DataProtectionPage/DataProtectionPage'))
const CookiesPolicyPage = lazy(() => import('./chamber/CookiesPolicyPage/CookiesPolicyPage'))

const Home = lazy(() => import('./chamber/Home/Home'))
const RCHome = lazy(() => import('./regionalChamber/pages/RCHome/RCHome'))
const WebPage = lazy(() => import('./components/WebPage/WebPage'))
const SectionPage = lazy(() => import('./components/SectionPage/SectionPage'))
const RCWebPage = lazy(() => import('./regionalChamber/pages/RCWebPage/RCWebPage'))
const RCSectionPage = lazy(() => import('./regionalChamber/pages/RCSectionPage/RCSectionPage'))
const RCSingleArticle = lazy(() =>
    import('./regionalChamber/pages/RCSingleArticle/RCSingleArticle')
)
const AllRCNews = lazy(() => import('./regionalChamber/pages/AllRCNews/AllRCNews'))
const RCContact = lazy(() => import('./regionalChamber/pages/RCContact/RCContact'))
const RCEvents = lazy(() => import('./regionalChamber/pages/RCEvents/RCEvents'))
const RCEventDetails = lazy(() => import('./regionalChamber/pages/RCEventDetails/RCEventDetails'))

const ChamberRoute = ({ component: Component, ...rest }) => {
    const AnalyticsComponent = withAnalytics(Component)
    return <Route {...rest} render={props => <AnalyticsComponent {...props} />} />
}

export const ChamberRoutes = () => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {/* About */}
            <ChamberRoute
                key="pregled-spoljnotrgovinskih-kretanja"
                exact
                path="/pregled-spoljnotrgovinskih-kretanja"
                component={GoogleMapPage}
            />
            <ChamberRoute exact path="/o-nama" component={AboutUs} key="about-us" />
            <ChamberRoute path="/predsednik" component={President} key="president" />
            <ChamberRoute path="/skupstina" component={Assembly} key="assembly" />
            <ChamberRoute
                path="/nadzorni-odbor"
                component={SupervisoryCommittee}
                key="super-committee"
            />
            <ChamberRoute
                path="/upravni-odbor"
                component={SteeringCommittee}
                key="steering-committee"
            />

            {/* Sector and association */}
            <ChamberRoute path="/sektor/:slug" component={Sector} key="sector" />
            <ChamberRoute path="/udruzenje/:slug" component={Association} key="association" />

            {/* Events */}
            <ChamberRoute
                path="/dogadjaji/:category"
                component={AllEventsByCategory}
                key="category-events"
            />
            <ChamberRoute path="/dogadjaj/:slug" component={EventDetails} key="single-event" />
            <ChamberRoute path="/dogadjaji" component={AllEvents} key="events" />

            <ChamberRoute
                path="/regionalne-komore"
                component={AllChambers}
                key="regional-chambers"
            />

            {/* Services */}
            <ChamberRoute
                path="/komorske-usluge/:slug"
                component={ServiceDetails}
                key="service-details"
            />
            <ChamberRoute
                path="/komorske-usluge"
                component={ChamberServices}
                key="chamber-services"
            />

            {/* News */}
            <ChamberRoute path="/sve-vesti/sektor/:slug" component={SectorNews} key="sector-news" />
            <ChamberRoute
                path="/sve-vesti/udruzenje/:slug"
                component={AssociationNews}
                key="association-news"
            />
            <ChamberRoute
                path="/sve-vesti/kategorija/:id/:slug?"
                component={CategoryNews}
                key="category-news"
            />
            <ChamberRoute
                path="/vesti/:slug/:articleId?"
                component={SingleArticle}
                key="single-news"
            />
            <ChamberRoute path="/pregled-svih-vesti" component={News} key="news" />

            {/* Custom Web page */}
            <ChamberRoute path="/strana/sekcija/:slug?" component={SectionPage} key="web-page" />

            <Route
                exact
                path="/Klik3mreza.aspx"
                render={() => <Redirect to="/strana/klik3-mreza" />}
            />

            <ChamberRoute path="/strana/:slug?" component={WebPage} key="web-page" />
            <ChamberRoute path="/kontakt" component={ContactPage} key="contact" />
            <ChamberRoute path="/strana/sekcija/:slug?" component={SectionPage} key="web-page" />
            <ChamberRoute path="/strana/:slug?" component={WebPage} key="web-page" />
            <ChamberRoute path="/kontakt" component={ContactPage} key="contact" />
            <ChamberRoute
                path="/zastita-podataka"
                component={DataProtectionPage}
                key="data-protection"
            />
            <ChamberRoute
                path="/politika-koriscenja-kolacica"
                component={CookiesPolicyPage}
                key="cookies-policy"
            />

            <ChamberRoute key="home" exact path="/" component={Home} />

            <ChamberRoute
                key="dubai-expo-2020"
                path="/dubai-expo-2020-aktivnost"
                component={SingleActivityPage}
            />
            <ChamberRoute
                key="dubai-expo-2020"
                exact
                path="/dubai-expo-2020"
                component={DubaiExpo2020}
            />
            <ChamberRoute
                key="open-balkan"
                path="/open-balkan-aktivnost"
                component={SingleActivityPageOP}
            />
            <ChamberRoute key="open-balkan" exact path="/open-balkan" component={OpenBalkan} />
            <ChamberRoute
                key="open-balkan-sporazumi"
                path="/open-balkan-sporazumi/:slug"
                component={OpenBalkanSporazumPage}
            />
            <ChamberRoute
                key="open-balkan-ucesnici"
                path="/open-balkan-ucesnici"
                component={OpenBalkanUcesniciPage}
            />

            <Route key="not-found-page" component={NotFoundPage} />
        </Switch>
    </Suspense>
)

const RegionalRoute = ({ component: Component, ...rest }) => {
    const [chamber] = window.location.hostname.split('.')
    const slug = SLUGS[chamber]
    const AnalyticsComponent = withAnalytics(Component)
    return <Route {...rest} render={props => <AnalyticsComponent slug={slug} {...props} />} />
}

export const RegionalRoutes = () => (
    <Suspense fallback={<Loader />}>
        <Switch>
            <RegionalRoute
                path={'/sve-vesti/kategorija/:id/:slug?'}
                component={RCCategoryNews}
                key="category-news"
            />
            <RegionalRoute key="category-news" path={`/sve-vesti`} component={AllRCNews} />
            <RegionalRoute
                key="single-news"
                path={`/vesti/:newsSlug/:articleId?`}
                component={RCSingleArticle}
            />
            <RegionalRoute
                path={`/strana/sekcija/:pageSlug?`}
                component={RCSectionPage}
                key="web-page"
            />
            <RegionalRoute key="events" path={`/strana/dogadjaji/`} component={RCEvents} />
            <RegionalRoute
                key="single-event"
                path={`/strana/dogadjaj/:chamberId/:slug`}
                component={RCEventDetails}
            />

            <RegionalRoute path={`/strana/:pageSlug`} component={RCWebPage} key="web-page" />

            <RegionalRoute key="contact" path={`/kontakt`} component={RCContact} />
            <RegionalRoute
                path="/zastita-podataka"
                component={RCDataProtection}
                key="data-protection"
            />

            <RegionalRoute key="home" exact path="/" component={RCHome} />
            <RegionalRoute key="not-found-page" component={RCNotFoundPage} />
        </Switch>
    </Suspense>
)
