import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { eventCommunicator } from '../../../communicators/eventCommunicator'

import { getDate } from '../../../config/functions'

import { TopSectionTitle } from '../TopSectionTitle/TopSectionTitle'
import { EventsList } from '../EventsList/EventsList'
import { Pagination } from '../Pagination/Pagination'
import { DetailsLink } from '../DetailsLink/DetailsLink'
import NoEvents from '../NoEvents/NoEvents'
import { SectionBlock } from '../SectionBlock/SectionBlock'

class EventsSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            events: [],
            pagesList: [],
            lastPage: 1
        }

        this.updateEvents = this.updateEvents.bind(this)
    }

    getPages(n) {
        const array = []
        for (let i = 1; i <= n; i++) {
            array.push(i)
        }

        return array
    }

    async updateEvents(currentPage) {
        this.loadEvents(currentPage)
    }

    componentDidMount() {
        this.loadEvents(1)
    }

    async loadEvents(page) {
        const { id, n, queryParam } = this.props

        try {
            const eventsData = await eventCommunicator.fetchRelatedEvents(
                getDate(new Date()),
                { [queryParam]: id, page },
                n
            )

            const events = eventsData.events
            const pagination = eventsData.pagination

            this.setState({
                events,
                lastPage: pagination.totalPages,
                pagesList: this.getPages(pagination.totalPages)
            })
        } catch (error) {
            console.error(error.message)
        }
    }

    render() {
        const { events, pagesList, lastPage } = this.state
        const { t, title, dark, onShowAllEvents } = this.props

        return (
            <SectionBlock withContainer>
                <div className="events-section">
                    <TopSectionTitle title={title} underlined dark={!dark} centered />

                    {_.isEmpty(events) ? (
                        <NoEvents dark={!dark} />
                    ) : (
                        <Fragment>
                            <EventsList eventsList={events} dark={dark} />
                            {pagesList.length > 1 && (
                                <Pagination
                                    lastPage={lastPage}
                                    pagesList={pagesList}
                                    updateContent={this.updateEvents}
                                    light={!dark}
                                />
                            )}
                            <DetailsLink
                                text={t('association.eventsSection.details')}
                                onDetailsClick={onShowAllEvents}
                                white
                            />
                        </Fragment>
                    )}
                </div>
            </SectionBlock>
        )
    }
}

EventsSection.propTypes = {
    id: PropTypes.number,
    queryParam: PropTypes.string,
    n: PropTypes.number,
    title: PropTypes.string,
    dark: PropTypes.bool,
    onShowAllEvents: PropTypes.func
}

EventsSection.defaultProps = {
    title: '',
    dark: false
}

export { EventsSection }
export default withTranslation()(EventsSection)
