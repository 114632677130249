import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const Redirects = () => (
    <>
        <Route path="/Default.aspx" render={() => <Redirect to="/" />} />
        <Route path="/Naslovna.aspx" render={() => <Redirect to="/" />} />
        <Route path="/ScriptResource.axd" render={() => <Redirect to="/" />} />
        <Route
            path="/strana/sekcija/pregled-spoljnotrgovinskih-kretanja"
            render={() => <Redirect to="/pregled-spoljnotrgovinskih-kretanja" />}
        />
        <Route path="/Vesti.aspx" render={() => <Redirect to="/pregled-svih-vesti" />} />
        <Route path="/Izdvajamo.aspx" render={() => <Redirect to="/pregled-svih-vesti" />} />

        <Route path="/Dogadjaji.aspx" render={() => <Redirect to="/dogadjaji" />} />
        <Route path="/Usluge.aspx" render={() => <Redirect to="/komorske-usluge" />} />
        <Route path="/ONama.aspx" render={() => <Redirect to="/o-nama" />} />

        <Route path="/ZaMedije.aspx" render={() => <Redirect to="/strana/pr-corner" />} />
        <Route path="/kontakt.aspx" render={() => <Redirect to="/kontakt" />} />

        <Route
            path="/preduzetnikonline.aspx"
            render={() => <Redirect to="/strana/sektor-preduzetnistva" />}
        />

        <Route
            path="/Predstavnistva.aspx"
            render={() => <Redirect to="/strana/internacionalizacija" />}
        />
        <Route
            path="/MSaradnja.aspx"
            render={() => <Redirect to="/strana/internacionalizacija" />}
        />
        <Route
            path="/Podrskauizvozu.aspx"
            render={() => <Redirect to="/strana/podrska-poslovanju" />}
        />

        {/* Applikacije */}
        <Route path="/Aplikacije.aspx" render={() => <Redirect to="/komorske-usluge" />} />
        <Route
            path="/SertifikatPKS"
            render={() => <Redirect to="/komorske-usluge/elektronski-sertifikat" />}
        />
        <Route
            path="/AtaKarnet"
            render={() => <Redirect to="/strana/sekcija/ata-karnet-ata-karnet-aplikacija" />}
        />
        <Route exact path="/Aplikacije" render={() => <Redirect to="/komorske-usluge" />} />
        <Route
            path="/Aplikacije/edukacija.aspx"
            render={() => <Redirect to="/strana/edukacija" />}
        />
        <Route
            path="/Aplikacije/EdukacijaDetails.aspx"
            render={() => <Redirect to="/strana/edukacija" />}
        />
        <Route
            path="/Aplikacije/CEznak.aspx"
            render={() => <Redirect to="/strana/sekcija/pks-ce-znak-aplikacija" />}
        />
        <Route
            path="/Aplikacije/Proveraclanarine.aspx"
            render={() => (
                <Redirect to="/strana/sekcija/pks-clanstvo-i-clanarina-obracun-clanarine" />
            )}
        />
        <Route
            path="/ca"
            render={() => (
                <Redirect to="/strana/sekcija/usluge-elektronski-sertifikat-procedura-izdavanja " />
            )}
        />
    </>
)

export default Redirects
