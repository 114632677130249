import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { IdNameEntity } from '../../../../entities/IdNameEntity'
import { Event } from '../../../../entities/Event'

import './EventsItem.css'

const EventsItem = ({ eventsItem, category, dark }) => {
    const {
        slug,
        eventThumbnail,
        truncatedTitle,
        displayDate,
        htmlContentShortTruncated,
        backgroundColor
    } = eventsItem

    const darkListClass = dark ? 'events-item--dark' : ''
    const hasOriginalImageClass = eventThumbnail ? 'width-image' : ''

    return (
        <div className={`events-item ${darkListClass}`}>
            <Link to={`/dogadjaj/${slug}`}>
                {eventThumbnail && (
                    <div
                        className="events-item__image"
                        style={{ backgroundImage: `url("${eventThumbnail}")` }}
                    />
                )}
                <div
                    className={`events-item__content events-item__content--${hasOriginalImageClass}`}
                >
                    <div className="events-item__content__info">
                        <h6>
                            <span>{displayDate}</span>
                        </h6>
                        <div
                            className={`events-item__content__info__category events-item__content__info__category--${
                                category.id
                            }`}
                            style={{ backgroundColor: backgroundColor }}
                        >
                            <div>{category.name}</div>
                        </div>
                    </div>
                    <h5>
                        <span>{truncatedTitle}</span>
                    </h5>
                    <p dangerouslySetInnerHTML={htmlContentShortTruncated} />
                </div>
            </Link>
        </div>
    )
}

EventsItem.propTypes = {
    eventsItem: PropTypes.instanceOf(Event).isRequired,
    category: PropTypes.instanceOf(IdNameEntity),
    dark: PropTypes.bool
}

EventsItem.defaultProps = {
    dark: false
}

export { EventsItem }
