import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { PKS_NEWS_CATEGORIES } from '../../../../config/pks-constants'
import { newsCommunicator } from '../../../../communicators/newsCommunicator'

import { RCPage } from '../../RCPage/RCPage'
import GeneralNews from '../../../components/GeneralNews/GeneralNews'

import './RCCategoryNews.css'

class RCCategoryNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            newsCategory: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.loadNewsCategories()
    }

    async loadNewsCategories() {
        try {
            const newsCategories = await newsCommunicator.fetchNewsCategories()

            const categoryID = parseInt(this.props.match.params.id)
            const newsCategory = newsCategories.find(newsCategory => newsCategory.id === categoryID)

            this.setState({ newsCategory, isLoading: false })
        } catch (error) {
            console.error(error.message)
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { t } = this.props
        const { newsCategory, isLoading } = this.state

        return (
            <RCPage isLoading={isLoading}>
                {newsCategory && (
                    <div className="category-news">
                        <GeneralNews
                            id={newsCategory.id}
                            queryParam="news_category_id"
                            title={newsCategory.name}
                            subtitle={t('sectorNews.subtitle')}
                            bcgImg="/images/all-news-top-img.png"
                            withApproval={newsCategory.id === PKS_NEWS_CATEGORIES.REGIONAL_CHAMBERS}
                        />
                    </div>
                )}
            </RCPage>
        )
    }
}

RCCategoryNews.propTypes = {
    t: PropTypes.func
}

export default withTranslation()(RCCategoryNews)
