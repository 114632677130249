import React, { Fragment } from 'react'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { ChamberRoutes } from './../Routes'
import HelmetComponent from '../components/HelmetComponent/HelmetComponent'
import Redirects from '../Redirects'

const Chamber = () => (
    <Fragment>
        <Redirects />
        <HelmetComponent />
        <Header />
        <ChamberRoutes />
        <Footer />
    </Fragment>
)

export { Chamber }
