import PropTypes from 'prop-types'

const linkItemProp = PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
})

const addressProp = PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string
})

const contactProp = PropTypes.shape({
    phone: PropTypes.string,
    fax: PropTypes.string,
    email: PropTypes.string
})

const idNumProp = PropTypes.shape({
    pib: PropTypes.shape({
        title: PropTypes.string,
        number: PropTypes.string
    }),
    matNum: PropTypes.string
})

const infoProp = PropTypes.shape({
    name: PropTypes.string,
    address: addressProp,
    contact: contactProp,
    idNum: idNumProp
})

export { linkItemProp, infoProp }
