import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import './NoEvents.css'

export const NoEvents = ({ t, dark }) => {
    // const onSendClick = () => {}
    const iconImg = dark ? '/images/no-events-dark.png' : '/images/no-events.png'
    const className = dark ? 'dark' : ''

    return (
        <div className={`events-list__no-events events-list__no-events--${className}`}>
            <img src={iconImg} alt="no events" />
            <div className="events-list__no-events__msg">{t('NoEvents.message1')}</div>
            {/* <div className="events-list__no-events__msg">{t('NoEvents.message2')}</div> */}
            {/* <div className="events-list__no-events__form">
                <input type="text" name="send" placeholder="E-mail" />
                <div className="events-list__no-events__form__button" onClick={onSendClick}>
                    {t('NoEvents.buttonText')}
                </div>
            </div> */}
        </div>
    )
}

NoEvents.propTypes = {
    t: PropTypes.func
}

export default withTranslation()(NoEvents)
