import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import './RCFooterInfo.css'

const RCFooterInfo = ({ t, name, contactDetails, pib, identificationNumber }) => {
    return (
        <div className="RC-footer-info">
            <h4>{name}</h4>
            <div className="RC-footer-info__details" dangerouslySetInnerHTML={contactDetails} />
            {/* <div className="RC-footer-info__id">
                <h5>
                    {t('regionalChamber.footer.pib.title')}
                    <span>{pib}</span>
                </h5>
                <h5>
                    {t('regionalChamber.footer.idNumber.title')}
                    <span>{identificationNumber}</span>
                </h5>
            </div> */}
        </div>
    )
}

RCFooterInfo.propTypes = {
    t: PropTypes.func,
    name: PropTypes.string,
    contactDetails: PropTypes.shape({
        __html: PropTypes.string
    }),
    pib: PropTypes.number,
    identificationNumber: PropTypes.number
}
export { RCFooterInfo }
export default withTranslation()(RCFooterInfo)
