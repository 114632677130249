import { IdNameEntity } from './IdNameEntity'

class Municipality extends IdNameEntity {
    constructor({ id, name, register_number }) {
        super({ id, name })
        this.registerNumber = register_number
    }
}

export { Municipality }
