import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { News } from '../../../../../../entities/News'
import { Event } from '../../../../../../entities/Event'

import { AllNewsItem } from '../../../../AllNewsItem/AllNewsItem'
import { DateEventItem } from '../../../../DateEventItem/DateEventItem'
import { Pagination } from '../../../../Pagination/Pagination'

import './SearchResultsView.css'

const SearchResultsView = ({
    searchResults,
    lastPage,
    pagesList,
    updateSearchResults,
    message
}) => {
    if (!_.isEmpty(message)) {
        return <div className="search-results-view__message">{message}</div>
    }

    return (
        <div className="search-results-view">
            {searchResults.map((result, i) => {
                if (result instanceof News) {
                    return <AllNewsItem newsItem={result} key={i} dark />
                } else if (result instanceof Event) {
                    return <DateEventItem event={result} key={i} />
                } else {
                    return null
                }
            })}
            {pagesList.length > 1 && (
                <Pagination
                    lastPage={lastPage}
                    pagesList={pagesList}
                    updateContent={updateSearchResults}
                    light
                />
            )}
        </div>
    )
}
export { SearchResultsView }

SearchResultsView.propTypes = {
    searchResults: PropTypes.array,
    lastPage: PropTypes.number,
    pagesList: PropTypes.array,
    updateSearchResults: PropTypes.func,
    message: PropTypes.string
}
