import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { News } from '../../../../entities/News'

import './TopNewsItem.css'

const TopNewsItem = ({ newsItem, isVertical = false }) => {
    const { slug, horizontalLargeImage, verticalLargeImage, displayDate, truncatedTitle } = newsItem
    const image = isVertical ? verticalLargeImage : horizontalLargeImage
    return (
        <div className="top-news-item">
            <Link to={`/vesti/${slug}`}>
                <div
                    className="top-news-item__content"
                    style={{ backgroundImage: `url("${image}")` }}
                />
                <div className="top-news-item__title">
                    <h6>
                        <span>{displayDate}</span>
                    </h6>
                    <h5>
                        <span>{truncatedTitle}</span>
                    </h5>
                </div>
            </Link>
        </div>
    )
}

TopNewsItem.propTypes = {
    newsItem: PropTypes.instanceOf(News).isRequired,
    isVertical: PropTypes.bool
}

export { TopNewsItem }
