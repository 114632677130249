import React from 'react'

import { withTranslation } from 'react-i18next'

import { SLUGS } from '../../../../config/pks-constants'

import { RCPage } from '../../RCPage/RCPage'
import AllRCNews from '../AllRCNews/AllRCNews'

import './RCNotFoundPage.css'

const [chamber] = window.location.hostname.split('.')
const slug = SLUGS[chamber]

const RCNotFoundPage = ({ t }) => (
    <RCPage>
        <div className="not-found-page">
            <h4>404</h4>
            <h5 className="not-found-page__title">{t('notFoundPageTitle')}</h5>
            <AllRCNews slug={slug} />
        </div>
    </RCPage>
)

export default withTranslation()(RCNotFoundPage)
