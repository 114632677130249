import React from 'react'
import PropTypes from 'prop-types'

import './RCSectionTitle.css'

const RCSectionTitle = ({ title, dark }) => {
    const darkClass = dark ? 'dark-bg-title' : ''

    return <h3 className={`section-title-RC ${darkClass}`}>{title}</h3>
}

RCSectionTitle.propTypes = {
    title: PropTypes.string,
    dark: PropTypes.bool
}

RCSectionTitle.defaultProps = {
    dark: false
}

export { RCSectionTitle }
