import React from 'react'
import PropTypes from 'prop-types'

import { Loader } from '../../components/Loader/Loader'
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary'
import HelmetComponent from '../../components/HelmetComponent/HelmetComponent'

import './RCPage.css'

const RCPage = ({
    children,
    title,
    description,
    ogType,
    ogTitle,
    image,
    twitterTitle,
    keywords,
    isLoading = false
}) => (
    <>
        <HelmetComponent
            title={title}
            description={description}
            ogTitle={ogTitle}
            ogType={ogType}
            image={image}
            twitterTitle={twitterTitle}
            keywords={keywords}
        />
        <Loader isLoading={isLoading}>
            <ErrorBoundary>
                <main className="RC-page gray-bg">{children}</main>
            </ErrorBoundary>
        </Loader>
    </>
)

RCPage.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool
}

export { RCPage }
