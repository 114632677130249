import React from 'react'
import PropTypes from 'prop-types'

import './SectionBlock.css'

const SectionBlock = ({ withContainer = false, dark, children }) => (
    <section className={dark ? 'dark-blue' : ''}>
        {withContainer ? (
            <div className="section-container container-1280">{children}</div>
        ) : (
            children
        )}
    </section>
)

SectionBlock.propTypes = {
    children: PropTypes.node,
    dark: PropTypes.bool
}

export { SectionBlock }
