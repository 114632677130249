import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { newsCommunicator } from '../../../../../communicators/newsCommunicator'

import { Hide } from '../../../../components/Hide'
import { RCSectionBlock } from '../../../RCSectionBlock/RCSectionBlock'
import { RCSectionTitle } from '../../../RCSectionTitle/RCSectionTitle'
import { ErrorBoundary } from '../../../../components/ErrorBoundary/ErrorBoundary'
import { RCTopNewsItem } from './RCTopNewsItem/RCTopNewsItem'

import './RCTopNews.css'

class RCTopNews extends Component {
    constructor(props) {
        super(props)

        this.state = {
            markedNews: []
        }
    }

    async loadTopNews() {
        try {
            const { id } = this.props

            const markedNews = await newsCommunicator.fetchFeaturedRCNews(id)

            this.setState({ markedNews })
        } catch (error) {
            console.error(error.message)
        }
    }

    componentDidMount() {
        this.loadTopNews()
    }

    render() {
        const { title } = this.props
        const { markedNews } = this.state

        const newsClass = markedNews.length > 2 ? '' : 'rc-top-news__content__left--centered'

        return (
            <Hide ifEmpty={markedNews}>
                <RCSectionBlock withContainer>
                    <RCSectionTitle title={title} />
                    <ErrorBoundary>
                        <div className="rc-top-news__content">
                            <div className={`rc-top-news__content__left ${newsClass}`}>
                                {markedNews.slice(0, 2).map((item, i) => (
                                    <RCTopNewsItem newsItem={item} key={item.id} />
                                ))}
                            </div>
                            {markedNews.length > 2 && (
                                <div className="rc-top-news__content__right">
                                    <RCTopNewsItem
                                        newsItem={markedNews[2]}
                                        key={markedNews[2].id}
                                        isVertical
                                    />
                                </div>
                            )}
                        </div>
                    </ErrorBoundary>
                </RCSectionBlock>
            </Hide>
        )
    }
}

RCTopNews.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
}

export { RCTopNews }
