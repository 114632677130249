import React, { useState, useEffect } from 'react'
import i18n from './../../../config/i18n'

import SingleActivityListItem from './SingleActivityListItem/SingleActivityListItem'
import './DubaiExpo2020.css'

const DubaiExpo2020 = ({
    aktuelnostiTitle = i18n.t('dubaiExpo2020.aktuelnostiTitle'),
    mainTitle1 = i18n.t('dubaiExpo2020.mainTitle'),
    mainSubtitle = i18n.t('dubaiExpo2020.mainSubtitle'),
    introTxt1 = i18n.t('dubaiExpo2020.introTxt1'),
    introTxt2 = i18n.t('dubaiExpo2020.introTxt2'),
    documentsTitle = i18n.t('dubaiExpo2020.documentsTitle')
}) => {
    const [aktuelnosti, setAktuelnosti] = useState([])
    const [dokumenta, setDokumenta] = useState([])

    useEffect(() => {
        let lang = JSON.parse(localStorage.getItem('language'))

        fetch('https://api.pks.rs/public-api/dubai-expo/aktuelnosti?offset=100', {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(data => setAktuelnosti(data.body.entries))

        fetch('https://api.pks.rs/public-api/dubai-expo/dokumenta', {
            method: 'GET',
            headers: {
                Language: lang ? lang : ''
            }
        })
            .then(response => response.json())
            .then(data => setDokumenta(data.body.entries))
    }, [])

    const mapAktuelnosti = () => {
        return aktuelnosti.map((aktuelnost, index) => {
            return (
                <SingleActivityListItem data={aktuelnost} key={`single-activity-item-${index}`} />
            )
        })
    }

    const mapDokumenta = () => {
        return dokumenta.map((dokument, index) => {
            return (
                <a
                    className="dubai-expo-2020-document-wrapper"
                    key={`dubai-document-${index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dokument.file.file_path}
                >
                    <img src="/images/pdf-icon.svg" alt="pdf" />
                    <p className="dubai-expo-2020-single-document-title">{dokument.name}</p>
                </a>
            )
        })
    }

    return (
        <main>
            <div className="dubai-expo-2020-top-banner" />
            <div className="section-container container-1280">
                <h1 className="top-section-title__text dark top-section-title__text--underlined">
                    {mainTitle1}
                </h1>
                <p className="top-section-title__text dark dubai-expo-subtitle">{mainSubtitle}</p>

                <p className="dubai-expo-2020-regular-txt">{introTxt1}</p>
                <p className="dubai-expo-2020-regular-txt">{introTxt2}</p>

                <h2 className="top-section-title__text dark top-section-title__text--underlined">
                    {aktuelnostiTitle}
                </h2>

                <div className="dubai-expo-2020-section-wrapper">{mapAktuelnosti()}</div>

                <h2 className="top-section-title__text dark top-section-title__text--underlined dubaiexpo-mrgtop">
                    {documentsTitle}
                </h2>

                <div className="dubai-expo-2020-section-wrapper">{mapDokumenta()}</div>
            </div>
        </main>
    )
}

export default DubaiExpo2020
