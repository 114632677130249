import React from 'react'

import { withTranslation } from 'react-i18next'

import { PKS_NEWS_CATEGORY } from '../../../config/pks-constants'

import { Page } from '../Page/Page'
import { SectionBlock } from '../SectionBlock/SectionBlock'
import { AllNews } from '../AllNews/AllNews'

import './NotFoundPage.css'

const NotFoundPage = ({ t }) => (
    <Page>
        <div className="not-found-page">
            <SectionBlock withContainer>
                <h4>404</h4>
                <h5 className="not-found-page__title">{t('notFoundPageTitle')}</h5>
            </SectionBlock>
            <AllNews
                id={PKS_NEWS_CATEGORY.NEWS_HOME_CATEGORY}
                queryParam={'news_category_id'}
                title={t('notFoundPage.suggestedTitle')}
                withApproval
            />
        </div>
    </Page>
)

export default withTranslation()(NotFoundPage)
