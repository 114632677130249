import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { Event } from '../../../entities/Event'
import { eventCommunicator } from '../../../communicators/eventCommunicator'

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { EventsItem } from './EventsItem/EventsItem'

import './EventsList.css'

class EventsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventsCategories: []
        }
    }

    async loadEventCategories() {
        try {
            const eventsCategories = await eventCommunicator.fetchAllCategories()

            this.setState({ eventsCategories })
        } catch (error) {
            console.error(error.message)
        }
    }

    componentDidMount() {
        this.loadEventCategories()
    }

    render() {
        const { eventsList, dark } = this.props
        const eventsCenteredClass = eventsList.length > 2 ? '' : 'events-list_content--center'

        if (_.isEmpty(eventsList)) {
            return null
        }

        return (
            <ErrorBoundary>
                <div className="events-list">
                    <div className={`events-list__content ${eventsCenteredClass}`}>
                        {eventsList.map(item => {
                            const eventCategory = this.state.eventsCategories.find(
                                category => item.category.id === category.id
                            )

                            return (
                                eventCategory && (
                                    <EventsItem
                                        eventsItem={item}
                                        key={item.id}
                                        category={eventCategory}
                                        dark={dark}
                                    />
                                )
                            )
                        })}
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

EventsList.propTypes = {
    eventsList: PropTypes.arrayOf(PropTypes.instanceOf(Event)),
    dark: PropTypes.bool
}

EventsList.defaultProps = {
    dark: false
}

export { EventsList }
