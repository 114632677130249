import _ from 'lodash'

class AssociationGroup {
    constructor({ id, name, text }) {
        this.id = id
        this.title = { __html: name }
        this.text = { __html: text }
        this.active = false
    }

    get hasTitle() {
        return !_.isEmpty(this.title)
    }

    get hasText() {
        return !_.isEmpty(this.text)
    }

    setActiveToFalse() {
        this.active = false
    }

    toggleActive() {
        this.active = !this.active
    }
}

export { AssociationGroup }
