import { Section } from './Section'
import { ImageItem } from './ImageItem'
import { Member } from './Member'

import _ from 'lodash'

export class SectionLink {
    constructor({ title, slug, contacts, application_link }) {
        this.title = title
        this.slug = slug
        this.applicationLink = application_link
        this.contactList = contacts
    }

    get hasContacts() {
        return !_.isEmpty(this.contactList)
    }

    get contacts() {
        return this.hasContacts && this.contactList.map(contactPerson => new Member(contactPerson))
    }
}

export class Page {
    constructor(page) {
        this.id = page.id
        this.title = page.title
        this.sectionTitle = page.section_title
        this.image = page.header_image
        this.slug = page.slug
        this.description = page.description
        this.pageSplit = page.split
        this.category = page.category
        this.newsCategories = page.news_categories
        this.departments = page.departments
        this.links = page.links
        this.sectionList = page.sections
        this.categoryId = page.category && page.category.id
        this.applicationLink = page.application_link
    }

    get hasDescription() {
        return !_.isEmpty(this.description)
    }

    get pageDescription() {
        const temporalDIV = document.createElement('div')
        temporalDIV.innerHTML = this.description

        return _.truncate(temporalDIV.textContent, { length: 120, separator: ' ' }) || ''
    }

    get headerImage() {
        return this.image ? new ImageItem(this.image).url : null
    }

    get htmlDescription() {
        return {
            __html: this.description
        }
    }

    get news() {
        return this.newsCategories
    }

    get split() {
        return Boolean(this.pageSplit)
    }

    get hasSectionLinks() {
        return !_.isEmpty(this.sectionLinks)
    }

    get sectionLinks() {
        return this.links.map(link => new SectionLink(link))
    }

    get modalSectionLinks() {
        return [this.parliamentModal, this.boardsModal, this.sectionModal, this.presidentModal]
    }

    get sections() {
        return this.sectionList.map(section => new Section(section))
    }
}
