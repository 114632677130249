import axios from 'axios'

class Http {
    get(url, options = {}, params = {}) {
        return axios({
            method: 'GET',
            url,
            params,
            ...options
        })
    }
}

export const http = new Http()
