import React from 'react'
import PropTypes from 'prop-types'

import './DetailsLink.css'

const DetailsLink = ({ text, onDetailsClick, white }) => {
    const colorClassName = white ? 'details-link__text--white' : ''

    return (
        <div className="details-link">
            <div className={`details-link__text ${colorClassName}`} onClick={onDetailsClick}>
                {text}
            </div>
        </div>
    )
}

DetailsLink.propTypes = {
    text: PropTypes.string,
    onDetailsClick: PropTypes.func,
    white: PropTypes.bool
}

DetailsLink.defaultProps = {
    white: false
}

export { DetailsLink }
