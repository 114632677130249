import React from 'react'
import PropTypes from 'prop-types'

import './LinkList.css'

const LinkList = ({ list, domain = '' }) => (
    <ul className="link-list">
        {list.map(({ name, slug }, i) => {
            const link = domain ? `http://${slug}.${domain}` : slug
            const target = domain ? '_blank' : ''
            return (
                <li key={i}>
                    <a href={link} target={target} rel="noopener noreferrer">
                        {name}
                    </a>
                </li>
            )
        })}
    </ul>
)

LinkList.propTypes = {
    domain: PropTypes.string
}

export { LinkList }
