import _ from 'lodash'
import { ImageItem } from './ImageItem'
import { Page } from './Page'
import { PKS_SOCIAL_LINKS } from '../config/pks-constants'

class RegionalChamber {
    constructor({
        id,
        name_slug,
        name,
        full_name,
        header_image,
        about,
        regulations,
        contact,
        address,
        services,
        facebook,
        twitter,
        instagram,
        youtube,
        linkedin,
        pib,
        identification_number,
        pages
    }) {
        this.id = id
        this.slug = name_slug
        this.name = name
        this.fullName = full_name
        this.about = about
        this.regulations = regulations
        this.contact = contact
        this.address = address
        this.serviceList = services
        this.facebook = facebook
        this.twitter = twitter
        this.instagram = instagram
        this.youtube = youtube
        this.linkedin = linkedin
        this.pib = pib
        this.identificationNumber = identification_number
        this.headerImage = header_image
        this.regionalChamberPages = pages
    }

    get logo() {
        return this.headerImage ? new ImageItem(this.headerImage).url : null
    }

    get hasHtmlAbout() {
        return !_.isEmpty(this.about)
    }

    get htmlAbout() {
        return { __html: this.about }
    }

    get mapAddress() {
        return this.address.split(' ').join('%20')
    }

    get hasHtmlRegulations() {
        return !_.isEmpty(this.regulations)
    }

    get htmlRegulations() {
        return { __html: this.regulations }
    }

    get hasHtmlContact() {
        return !_.isEmpty(this.contact)
    }

    get htmlContact() {
        return this.hasHtmlContact ? { __html: this.contact } : null
    }

    get hasServices() {
        return !_.isEmpty(this.serviceList)
    }

    get fbLink() {
        return this.facebook || PKS_SOCIAL_LINKS.FB
    }

    get twitterLink() {
        return this.twitter || PKS_SOCIAL_LINKS.TWITTER
    }

    get instagramLink() {
        return this.instagram || PKS_SOCIAL_LINKS.INSTAGRAM
    }

    get youtubeLink() {
        return this.youtube || PKS_SOCIAL_LINKS.YOUTUBE
    }

    get linkedinLink() {
        return this.linkedin || PKS_SOCIAL_LINKS.LINKEDIN
    }

    get hasPages() {
        return !_.isEmpty(this.regionalChamberPages)
    }

    get pages() {
        return this.hasPages ? this.regionalChamberPages.map(page => new Page(page)) : []
    }

    get headerPages() {
        return this.pages.filter(page => page.categoryId === 1)
    }

    get activitiesPages() {
        return this.pages.filter(page => page.categoryId === 3)
    }

    get aboutChamberPages() {
        return this.pages.filter(page => page.categoryId === 4)
    }
}

export { RegionalChamber }
