import React from 'react'
import PropTypes from 'prop-types'

import './SectionButton.css'

const SectionButton = ({ buttonText, dark, onClickHandler }) => {
    const className = dark ? 'dark' : ''

    return (
        <div className={`section-button section-button--${className}`}>
            <button onClick={onClickHandler}>{buttonText}</button>
        </div>
    )
}

SectionButton.propTypes = {
    buttonText: PropTypes.string,
    dark: PropTypes.bool,
    onClickHandler: PropTypes.func
}

SectionButton.defaultProps = {
    dark: false,
    buttonText: ''
}

export { SectionButton }
