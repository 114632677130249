import { pksApiService } from '../services/pksApiService'

import { Event } from '../entities/Event'
import { PaginationInfo } from '../entities/PaginationInfo'
import { EventCategory } from '../entities/EventCategory'
import { setDateQueryParam } from '../config/functions'

class EventCommunicator {
    async fetchSingleEvent(id) {
        const myEvent = await pksApiService.fetchData(`/events/${id}`)
        return new Event(myEvent)
    }

    async fetchFeaturedEvents(categoryId, withApproval = false, offset = 3) {
        const queryParams = {
            special_event: 1,
            start_date_from: setDateQueryParam(0, true),
            event_category_id: categoryId,
            offset,
            internal_event: 0
        }

        const withApprovalParams = {
            special_event: 1,
            start_date_from: setDateQueryParam(0, true),
            event_category_id: categoryId,
            not_on_website: 0,
            offset,
            internal_event: 0
        }

        const qParams = withApproval ? withApprovalParams : queryParams

        const featuredEvents = await pksApiService.fetchData(`/events`, qParams)

        return featuredEvents.map(event => new Event(event))
    }

    async fetchRCFeaturedEvents(chamberId) {
        const params = {
            start_date_from: setDateQueryParam(0, true),
            special_event: 1,
            regional_chamber_id: chamberId,
            offset: 3,
            internal_event: 0
        }
        const featuredEvents = await pksApiService.fetchData(`/events`, params)

        return featuredEvents.map(event => new Event(event))
    }

    async fetchUpcomingEvents(page, startDate, endDate, categoryId, withApproval = false) {
        const queryParams = {
            page,
            start_date_from: startDate,
            start_date_to: endDate,
            event_category_id: categoryId,
            offset: 6,
            internal_event: 0
        }

        const withApprovalParams = {
            page,
            start_date_from: startDate,
            start_date_to: endDate,
            event_category_id: categoryId,
            offset: 6,
            not_on_website: 0,
            internal_event: 0
        }

        const qParams = withApproval ? withApprovalParams : queryParams
        const requestEndpoint = `/events`
        const responseBody = await pksApiService.fetchDataWithMeta(requestEndpoint, qParams)

        const pagination = new PaginationInfo(responseBody.pagination)
        const events = responseBody.entries.map(event => new Event(event))

        return {
            pagination,
            events
        }
    }

    async fetchRCUpcomingEvents(page, startDate, endDate, chamberId, categoryId) {
        const params = {
            page,
            start_date_from: startDate,
            start_date_to: endDate,
            event_category_id: categoryId,
            regional_chamber_id: chamberId,
            offset: 6,
            internal_event: 0
        }
        const requestEndpoint = `/events`
        const responseBody = await pksApiService.fetchDataWithMeta(requestEndpoint, params)

        const pagination = new PaginationInfo(responseBody.pagination)
        const events = responseBody.entries.map(event => new Event(event))

        return {
            pagination,
            events
        }
    }

    async fetchRelatedEvents(startDate, params, offset = 3, withApproval = false) {
        const requestEndpoint = `/events`

        const queryParams = {
            start_date_from: startDate,
            offset,
            internal_event: 0,
            ...params
        }

        const withApprovalParams = {
            start_date_from: startDate,
            offset,
            not_on_website: 0,
            internal_event: 0,
            ...params
        }

        const qParams = withApproval ? withApprovalParams : queryParams

        const responseBody = await pksApiService.fetchDataWithMeta(requestEndpoint, qParams)

        const pagination = responseBody.pagination
            ? new PaginationInfo(responseBody.pagination)
            : null
        const events = responseBody.entries
            ? responseBody.entries.map(event => new Event(event))
            : []

        return {
            pagination,
            events
        }
    }

    async fetchCalendarEvents(startDateFrom) {
        const requestEndpoint = `/events/calendar`
        const eventStartDates = await pksApiService.fetchData(requestEndpoint, {
            start_date_from: startDateFrom,
            not_on_website: 0,
            internal_event: 0
        })

        return eventStartDates
    }

    async fetchRCCalendarEvents(startDateFrom, chamberId) {
        const requestEndpoint = `/events/calendar`
        const eventStartDates = await pksApiService.fetchData(requestEndpoint, {
            start_date_from: startDateFrom,
            regional_chamber_id: chamberId,
            internal_event: 0
        })

        return eventStartDates
    }

    async fetchAllEvents(startDateFrom, startDateTo, offset) {
        const requestEndpoint = '/events'

        const events = await pksApiService.fetchData(requestEndpoint, {
            start_date_from: startDateFrom,
            start_date_to: startDateTo,
            not_on_website: 0,
            offset: offset,
            internal_event: 0
        })

        return events.map(event => new Event(event))
    }

    async RCFetchAllEvents(startDateFrom, startDateTo, offset, chamberId) {
        const requestEndpoint = '/events'

        const events = await pksApiService.fetchData(requestEndpoint, {
            start_date_from: startDateFrom,
            start_date_to: startDateTo,
            regional_chamber_id: chamberId,
            offset: offset,
            internal_event: 0
        })

        return events.map(event => new Event(event))
    }

    async fetchAllEventsByCategory(page, id) {
        const eventsEndPoint = '/events'

        const params = {
            page,
            event_category_id: id,
            offset: 10,
            internal_event: 0,
            not_on_website: 0
        }

        const responseBody = await pksApiService.fetchDataWithMeta(eventsEndPoint, params)

        const pagination = responseBody.pagination
            ? new PaginationInfo(responseBody.pagination)
            : null
        const events = responseBody.entries
            ? responseBody.entries.map(event => new Event(event))
            : []

        return {
            pagination,
            events
        }
    }

    async fetchAllCategories() {
        const eventsEndPoint = '/event-categories'

        const eventCategories = await pksApiService.fetchData(eventsEndPoint)

        return eventCategories.map(item => new EventCategory(item))
    }

    async fetchEventsByDepartment(page, departmentId) {
        const eventsEndPoint = '/events'

        const queryParams = {
            page: page,
            department_id: departmentId,
            start_date_from: setDateQueryParam(0, true),
            offset: 3,
            internal_event: 0
        }

        const responseBody = await pksApiService.fetchDataWithMeta(eventsEndPoint, queryParams)

        const pagination = responseBody.pagination
            ? new PaginationInfo(responseBody.pagination)
            : null
        const events = responseBody.entries
            ? responseBody.entries.map(event => new Event(event))
            : []

        return {
            pagination,
            events
        }
    }

    fetch(id) {
        return pksApiService.fetch('/events', id).then(event => new Event(event))
    }

    fetchPage(pageSize = 20, page = 1, queryParams = {}) {
        return pksApiService
            .fetchPage('/events', pageSize, page, queryParams)
            .then(eventList => eventList.map(event => new Event(event)))
    }

    fetchInfo(pageSize = 20, queryParams = {}) {
        return pksApiService
            .fetchInfo('/events', pageSize, queryParams)
            .then(newsInfo => new PaginationInfo(newsInfo))
    }
}

export const eventCommunicator = new EventCommunicator()
